import { SpacingSize, spacing } from "@RooUI";
import React from "react";
import { styled } from "styled-components";

export const HStack = styled.div<{
  $justifyContent?: React.CSSProperties["justifyContent"];
  $alignItems?: React.CSSProperties["alignItems"];
  $gap?: SpacingSize;
  $padding?: SpacingSize;
  $margin?: SpacingSize;
}>`
  display: flex;
  flex-direction: row;
  ${({ $justifyContent }) => ($justifyContent ? `justify-content: ${$justifyContent};` : undefined)}
  ${({ $alignItems }) => ($alignItems ? `align-items: ${$alignItems};` : "align-items: center;")}
  gap: ${({ $gap }) => ($gap ? spacing[$gap] : 0)};
  width: ${({ $justifyContent }) => ($justifyContent === "space-between" ? "100%" : "auto")};
  padding: ${({ $padding }) => ($padding ? spacing[$padding] : 0)};
  margin: ${({ $margin }) => ($margin ? spacing[$margin] : 0)};
`;

export const VStack = styled.div<{
  $justifyContent?: React.CSSProperties["justifyContent"];
  $alignItems?: React.CSSProperties["alignItems"];
  $gap?: SpacingSize;
  $padding?: SpacingSize;
  $margin?: SpacingSize;
  $paddingTop?: SpacingSize;
  $paddingBottom?: SpacingSize;
  $paddingLeft?: SpacingSize;
  $paddingRight?: SpacingSize;
  $width?: React.CSSProperties["width"];
}>`
  display: flex;
  flex-direction: column;
  ${({ $justifyContent }) => ($justifyContent ? `justify-content: ${$justifyContent};` : undefined)}
  ${({ $alignItems }) => ($alignItems ? `align-items: ${$alignItems};` : undefined)}
  gap: ${({ $gap }) => ($gap ? spacing[$gap] : 0)};
  padding: ${({ $padding }) => ($padding ? spacing[$padding] : 0)};
  margin: ${({ $margin }) => ($margin ? spacing[$margin] : 0)};
  padding-top: ${({ $paddingTop }) => ($paddingTop ? spacing[$paddingTop] : undefined)};
  padding-bottom: ${({ $paddingBottom }) => ($paddingBottom ? spacing[$paddingBottom] : undefined)};
  padding-left: ${({ $paddingLeft }) => ($paddingLeft ? spacing[$paddingLeft] : undefined)};
  padding-right: ${({ $paddingRight }) => ($paddingRight ? spacing[$paddingRight] : undefined)};
  width: ${({ $width }) => ($width ? $width : "auto")};
`;

export const Grow = styled.div`
  flex: 1;
`;
