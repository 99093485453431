import { Rank, Weekday } from "@RooBeta/types";

export const DEFAULT_LAT = 37.7749;
export const DEFAULT_LNG = -122.4194;
export const DEFAULT_LAT_LNG = { lat: DEFAULT_LAT, lng: DEFAULT_LNG };

export const DEFAULT_DESCRIPTION = "San Francisco, CA";

export const DEFAULT_DATE_RANGE_DAYS = 90;

export const DEFAULT_ZOOM = 9;

export const DEFAULT_RANK = "date" as const;

export const DISTANCES = [
  { label: " ", value: -1 },
  { label: "10 mi", value: 12 },
  { label: "25 mi", value: 11 },
  { label: "50 mi", value: 9 },
  { label: "75 mi", value: 8.5 },
  { label: "100 mi", value: 8 },
];

export const DISTANCES_RADIUS = [
  { label: " ", value: -1 },
  { label: "10 mi", value: 10 },
  { label: "25 mi", value: 25 },
  { label: "50 mi", value: 50 },
  { label: "75 mi", value: 75 },
  { label: "100 mi", value: 100 },
];

export const SORTS: { value: Rank; label: string }[] = [
  { value: "date", label: "Date" },
  { value: "distance", label: "Distance" },
  { value: "price", label: "Price" },
];

export const VIEWS: { label: string; value: "list" | "map" }[] = [
  { label: "List", value: "list" },
  { label: "Map", value: "map" },
];

export const WEEKDAYS = [
  { label: "Su", value: Weekday.Sunday },
  { label: "M", value: Weekday.Monday },
  { label: "Tu", value: Weekday.Tuesday },
  { label: "W", value: Weekday.Wednesday },
  { label: "Th", value: Weekday.Thursday },
  { label: "F", value: Weekday.Friday },
  { label: "Sa", value: Weekday.Saturday },
];

export const APPOINTMENT_NUMBER = ["1-5", "5-10", "10-15", "15-20", "20-25", "25+"];
