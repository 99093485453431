import { useMutation } from "@tanstack/react-query";
import { del } from "../../api/common/rooFetch";

interface DeleteUserPaymentsRequest {
  shiftGroupIds: number[];
}

interface DeleteUserPaymentsResponse {
  success: boolean;
}

export const useDeleteUserPayments = (onSuccess: () => void) => {
  return useMutation<DeleteUserPaymentsResponse, Error, DeleteUserPaymentsRequest>({
    mutationFn: async (request: DeleteUserPaymentsRequest) => {
      const response = await del<DeleteUserPaymentsRequest, DeleteUserPaymentsResponse>(
        "api/admin/users/payments",
        request
      );
      return response;
    },
    onSuccess,
  });
};
