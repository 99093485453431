import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { breakpoints } from "../../../constants/responsiveBreakpoints";
import { TabPanel } from "../TabPanel";

import { ButtonTabContainer, Overlay, TabAndPanelContainer } from "./ButtonTabsContainerStyles";
import { TabButton } from "./TabButton";

export interface ButtonTabContainerProps {
  tabNameArr: TabNameObj[];
  defaultSelectedTabIndex: number;
  overrideSelectedTabIndex?: number;
  onClickTab?: (e: any, selectedTabIndex: number) => void;
  children?: React.ReactNode;
  routeBasePath?: string;
  useRouteNavigation?: boolean;
}

interface TabNameObj {
  index: number;
  name: string;
  panelContent?: React.ReactNode;
  "data-testid"?: string;
  path?: string;
}

const formatUrlPath = (str: string): string => str.replace(/\s+/g, "-").toLowerCase();
const normalizeString = (str: string): string => str.replace(/[\s-]/g, "").toLowerCase();

export const ButtonTabs = (props: ButtonTabContainerProps) => {
  const {
    tabNameArr,
    defaultSelectedTabIndex,
    onClickTab,
    overrideSelectedTabIndex,
    routeBasePath,
    useRouteNavigation,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultSelectedTabIndex);
  const tabIndex = overrideSelectedTabIndex ?? selectedTabIndex;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isMobileViewport = window.innerWidth < breakpoints.mediumMediaBreakpoint;

  const updateRoutePath = useCallback(
    (tabName: string) => {
      history.push({
        pathname: `${routeBasePath}/${formatUrlPath(tabName)}`,
        search: location.search,
      });
    },
    [routeBasePath, location.search, history]
  );

  useEffect(() => {
    if (!useRouteNavigation || !routeBasePath) {
      return;
    }

    const pathParts = location.pathname.split("/");
    const lastPathPart = pathParts[pathParts.length - 1];
    const tabNameFromUrl = location.pathname === routeBasePath ? null : lastPathPart;

    if (tabNameFromUrl) {
      const tabIndex = tabNameArr.find(
        (t) => normalizeString(t.path || t.name) === normalizeString(tabNameFromUrl)
      )?.index;

      if (tabIndex !== undefined) {
        setSelectedTabIndex(tabIndex);
      } else {
        setSelectedTabIndex(0);
      }
    } else {
      setSelectedTabIndex(0);
      updateRoutePath(tabNameArr[0].path || tabNameArr[0].name);
    }
  }, [
    history,
    location.pathname,
    location.search,
    routeBasePath,
    useRouteNavigation,
    tabNameArr,
    updateRoutePath,
  ]);

  const handleTabClick = (e: any, index: number) => {
    if (useRouteNavigation && routeBasePath) {
      const tab =
        tabNameArr.find((t) => t.index === index)?.path ||
        tabNameArr.find((t) => t.index === index)?.name;
      tab && updateRoutePath(tab);
    }
    onClickTab?.(e, index);
  };

  return (
    <TabAndPanelContainer>
      {isMobileMenuOpen && <Overlay />}
      {tabNameArr.length > 1 && (
        <ButtonTabContainer {...props}>
          <>
            <TabButton
              isSelected={true}
              onClick={(e: React.MouseEvent) => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
                handleTabClick(e, tabIndex);
              }}
              index={tabIndex}
              isMobileHeaderTab
              isMobileMenuOpen={isMobileMenuOpen}
            >
              {tabNameArr[tabIndex].name}
            </TabButton>
            {tabNameArr?.map((tabNameObj) => {
              const { index, name, "data-testid": dataTestId } = tabNameObj;
              return (
                <TabButton
                  key={index}
                  isSelected={index === tabIndex}
                  onClick={(e: React.MouseEvent) => {
                    setSelectedTabIndex(index);
                    if (isMobileViewport) setIsMobileMenuOpen(!isMobileMenuOpen);
                    handleTabClick(e, index);
                  }}
                  index={index}
                  isMobileMenuOpen={isMobileMenuOpen}
                  data-testid={dataTestId}
                >
                  {name}
                </TabButton>
              );
            })}
          </>
        </ButtonTabContainer>
      )}
      {props.children}
      {tabNameArr?.map((tabNameObj) => {
        const { index, panelContent } = tabNameObj;
        return (
          <TabPanel key={index} isSelected={index === tabIndex} isMobileMenuOpen={isMobileMenuOpen}>
            {panelContent}
          </TabPanel>
        );
      })}
    </TabAndPanelContainer>
  );
};
