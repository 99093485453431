import { useCallback } from "react";

import { Event, EventTracker } from "@RooBeta/types";
import { EventName, EventType } from "@roo-dev/event-tracking";

import { useNewDesignExperiment } from "../useNewDesignExperiment";

interface TrackingOptions {
  eventName: EventName;
  eventType: EventType;
  context: Record<string, string>;
}

export const useTracking = ({ providerType }: { providerType: string }) => {
  const { isEnabled } = useNewDesignExperiment();
  return useCallback(
    (action: () => void, trackingOptions: TrackingOptions) => {
      return () => {
        EventTracker.send({
          eventName: trackingOptions.eventName,
          eventType: trackingOptions.eventType,
          entityType: providerType === "VET" ? Event.Entity.VET : Event.Entity.TECH,
          context: {
            ...trackingOptions.context,
            isNewExperience: isEnabled,
          },
        });
        action();
      };
    },
    [providerType, isEnabled]
  );
};
