import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import { Event, EventTracker } from "@RooBeta/types";
import {
  useGetUserPromoProposals,
  UserPromoProposal,
} from "@Roo/hooks/api/useGetUserPromoProposals";
import { useExpressInterestInPromoProposal } from "@Roo/hooks/api/useExpressInterestInPromoProposal";
import { CACHE } from "@Roo/api/utility/constants";
import { queryClient } from "@Roo/api/reactQueryClient";

export const usePromoProposalManagement = () => {
  const { data: activePromoProposals = [] } = useGetUserPromoProposals("active");
  const activePromoProposalForDashboard: UserPromoProposal =
    activePromoProposals.find((proposal) => !proposal.providerInterested) ??
    ({} as UserPromoProposal);

  const [showClaimPromoProposalModal, setShowClaimPromoProposalModal] = useState(false);
  const [showClaimPromoProposalConfirmationModal, setShowClaimPromoProposalConfirmationModal] =
    useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [dismissedPromoProposals, setDismissedPromoProposals] = useLocalStorage<number[]>(
    "dismissed-promo-proposals",
    []
  );

  const { mutateAsync: expressPromoProposalInterest } = useExpressInterestInPromoProposal({
    onMutate: () => setShowLoading(true),
    onSuccess: () => {
      setShowLoading(false);
    },
  });

  const claimPromoProposalMutation = async (promoProposalId: number) => {
    await expressPromoProposalInterest({ promoProposalId });
    setShowClaimPromoProposalModal(true);
    setShowClaimPromoProposalConfirmationModal(false);

    EventTracker.send({
      eventName: Event.Name.PROMO_PROPOSAL_REQUEST_OFFER,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.PROMO_PROPOSAL,
      entityId: promoProposalId,
    });
  };

  const handleOpenModal = (promoProposalId: number) => {
    EventTracker.send({
      eventName: Event.Name.PROMO_PROPOSAL_CLICK_LEARN_MORE,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.PROMO_PROPOSAL,
      entityId: promoProposalId,
    });
    const activePromoProposal = activePromoProposals.find(
      (proposal) => proposal.promoProposalId === promoProposalId
    );
    if (activePromoProposal?.providerInterested) {
      setShowClaimPromoProposalModal(true);
    } else {
      setShowClaimPromoProposalConfirmationModal(true);
    }
  };

  const handleDismiss = (promoProposalId: number) => {
    EventTracker.send({
      eventName: Event.Name.PROMO_PROPOSAL_CARD_DISMISSED,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.PROMO_PROPOSAL,
      entityId: promoProposalId,
    });
    setDismissedPromoProposals([...dismissedPromoProposals, promoProposalId]);
  };

  const dismissModals = () => {
    setShowClaimPromoProposalConfirmationModal(false);
    setShowClaimPromoProposalModal(false);
    queryClient.invalidateQueries([CACHE.USER_PROMO_PROPOSALS]);
  };

  return {
    showClaimPromoProposalModal,
    showClaimPromoProposalConfirmationModal,
    showLoading,
    dismissedPromoProposals,
    claimPromoProposalMutation,
    handleOpenModal,
    handleDismiss,
    dismissModals,
    showPromoProposalBanner:
      activePromoProposals.length &&
      activePromoProposalForDashboard.showBanner &&
      !dismissedPromoProposals.includes(activePromoProposalForDashboard.promoProposalId),
    activePromoProposals,
    activePromoProposalForDashboard,
  };
};
