import { useQuery } from "@tanstack/react-query";

import { get } from "../../api/common/rooFetch";
import { CACHE } from "../../api/utility/constants";
import { getUserPromoProposals as getUserPromoProposalsRoute } from "../../constants/apiRoutes";

export type UserPromoProposal = {
  promoProposalId: number;
  showBanner: boolean;
  promoId: number;
  bannerTitle: string;
  bannerDescription: string;
  providerInterested: boolean;
};

const getUserPromoProposals = async (status?: string) => {
  const result = await get<{ data: UserPromoProposal[] }>(getUserPromoProposalsRoute(status));
  return result?.data;
};

export const useGetUserPromoProposals = (status?: string) => {
  return useQuery([CACHE.USER_PROMO_PROPOSALS], () => getUserPromoProposals(status));
};
