import { useMutation } from "@tanstack/react-query";

import { CACHE } from "@Roo/api/utility/constants";
import { queryClient } from "@RooBeta/api";

import { post } from "../../../api/common/rooFetch";
import { dismissPromoReminder } from "../../../constants/apiRoutes";

type MutationVariables = {
  promoId: number;
  userId: number;
};

export const useDismissPromoReminder = () => {
  return useMutation({
    mutationFn: async ({ promoId, userId }: MutationVariables) => {
      const result = await post<{ promoId: number; userId: number }, boolean>(
        dismissPromoReminder(),
        {
          promoId,
          userId,
        }
      );
      queryClient.invalidateQueries({ queryKey: [CACHE.USER_PROMOS], exact: false });
      return result;
    },
  });
};
