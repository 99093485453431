import React from "react";

import { grayLightest, greenBase } from "../../styles/constants";
import { FlexLayout } from "../FlexLayout";
import { Icon } from "../Icon";

import {
  ToggleContainer,
  ToggleDiv,
  ToggleInput,
  ToggleLabel,
  ToggleSubText,
  ToggleText,
} from "./CustomToggleStyles";

export interface CustomToggleProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  name?: string;
  isFullWidth?: boolean;
  label?: string;
  subLabel?: string;
  "data-testid"?: string;
}

export const Toggle: React.FC<CustomToggleProps> = (props) => {
  const { checked, disabled, name, label, subLabel, isFullWidth } = props;
  const { "data-testid": dataTestId, ...propsWithoutTestId } = props;
  return (
    <ToggleContainer {...propsWithoutTestId} $isFullWidth={isFullWidth}>
      <FlexLayout style={{ flexDirection: "column" }}>
        <ToggleText {...propsWithoutTestId}>{label ? label : name}</ToggleText>
        <ToggleSubText {...propsWithoutTestId}>{subLabel ? subLabel : ""}</ToggleSubText>
      </FlexLayout>
      <ToggleLabel data-testid={dataTestId}>
        <ToggleInput type="checkbox" {...propsWithoutTestId} />
        <ToggleDiv checked={checked} disabled={disabled}>
          <Icon name="Check" size={12} color={disabled ? grayLightest : greenBase} />
        </ToggleDiv>
      </ToggleLabel>
    </ToggleContainer>
  );
};
