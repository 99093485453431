import React from "react";
import { VList } from "virtua";

import { NAVIGATION_HEIGHT, SEARCH_HEIGHT } from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { UngroupedShiftList } from "@RooBeta/types";
import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";

import { ShiftCard } from "./ShiftCard";
import { StyledScrollArea } from "./styles";
import { GlobalNotificationCard } from "../globalNotificationCard";
import { ShiftProposalHospitalList } from "./ShiftProposalHospitalList";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

type FlatShiftListProps = {
  shiftList: UngroupedShiftList;
  fixedHeight?: boolean;
};

export const FlatShiftList = ({ fixedHeight = true, ...props }: FlatShiftListProps) => {
  const { windowSize } = useViewportStore();
  const containerHeight = windowSize.height - (NAVIGATION_HEIGHT + SEARCH_HEIGHT);
  const { provider } = useRequiredAuthorizedUser();

  const renderRow = (virtualIndex: number) => {
    const shift = props.shiftList.shifts[virtualIndex];

    const showShiftProposalHospitalList = virtualIndex === props.shiftList.shifts.length - 1;
    return (
      <>
        {virtualIndex === 0 && <GlobalNotificationCard />}
        <ShiftCard
          shift={shift}
          count={props.shiftList.count}
          variant="list"
          pointOfEntry={ShiftCardPointOfEntry.MyShiftsPane}
          data-testid={`flatShiftListCard${shift.shiftId}`}
          provider={provider}
        />
        {showShiftProposalHospitalList && <ShiftProposalHospitalList />}
      </>
    );
  };

  return (
    <StyledScrollArea
      $height={containerHeight}
      $fixedHeight={fixedHeight}
      data-testid="flatShiftListScrollArea"
    >
      {() => (
        <div style={{ width: "100%", height: containerHeight }}>
          <VList count={props.shiftList.shifts.length} overscan={5}>
            {renderRow}
          </VList>
        </div>
      )}
    </StyledScrollArea>
  );
};
