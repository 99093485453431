import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { CACHE } from "@Roo/api/utility/constants";

import { APIResponse } from "../api/common/response";
import { get } from "../api/common/rooFetch";

export type UserTypeEndpoint = "vet" | "tech";

const verifyWithMicroDepositsSchema = z.object({
  arrival_date: z.number(),
  hosted_verification_url: z.string(),
  microdeposit_type: z.string(),
});

const nextActionSchema = z.object({
  type: z.literal("verify_with_microdeposits"),
  verify_with_microdeposits: verifyWithMicroDepositsSchema,
});

const setupIntentSchema = z.object({
  id: z.string(),
  status: z.literal("requires_action"),
  client_secret: z.string(),
  payment_method_types: z.array(z.string()),
  next_action: nextActionSchema,
});

export type SetupIntent = z.infer<typeof setupIntentSchema>;

const getSetupIntents = async (userType: UserTypeEndpoint): Promise<SetupIntent[]> => {
  const response = await get<APIResponse<{ setupIntents: unknown }>>(
    `api/${userType}/stripe/setupIntents`
  );

  const parsedResponse = z.array(setupIntentSchema).safeParse(response.data.setupIntents);

  if (!parsedResponse.success) {
    Sentry.captureException(parsedResponse.error);
    throw new Error("Invalid response format for SetupIntents.");
  }

  return parsedResponse.data;
};

interface UseGetPendingSetupIntentsProps {
  userType: UserTypeEndpoint;
}
export const useGetPendingSetupIntents = ({ userType }: UseGetPendingSetupIntentsProps) =>
  useQuery([CACHE.GET_STRIPE_SETUP_INTENTS], () => getSetupIntents(userType));
