import { FormLabel, Text } from "@RooUI";
import React from "react";

interface NotesProps {
  label: string;
  content?: string | null;
}

export const Notes = ({ label, content }: NotesProps) => {
  if (!content) return null;

  return (
    <div data-testid="notes">
      <FormLabel>
        <span data-testid="notesLabel">{label}</span>
      </FormLabel>
      <Text data-testid="notesContent">{content}</Text>
    </div>
  );
};
