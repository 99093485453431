import { useMutation } from "@tanstack/react-query";

import { del, queryClient } from "@RooBeta/api";
import { deleteShiftProposal } from "./endpoints";
import { CacheKey } from "@RooBeta/types";

export const useDeleteShiftProposal = (options?: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  return useMutation({
    mutationFn: async (params: { shiftProposalId: number }) =>
      del(deleteShiftProposal(params.shiftProposalId)),
    onSuccess: () => {
      options?.onSuccess();
      queryClient.invalidateQueries({ queryKey: [CacheKey.ShiftProposals, "active"] });
    },
    onError: options?.onError,
  });
};
