import { create } from "zustand";

import { ContractStatus, HospitalInfo, HospitalRatings, ShiftDetails } from "@RooBeta/types";

type Tab = "info" | "hospital" | "reviews" | "shifts";

type Errors = {
  shiftDetails: string | undefined;
  hospitalInfo: string | undefined;
  hospitalRatings: string | undefined;
  contractStatus: string | undefined;
};

type Loading = {
  shiftDetails: boolean;
  hospitalInfo: boolean;
  hospitalRatings: boolean;
  contractStatus: boolean;
};

interface ShiftDetailsStoreState {
  shiftDetails: ShiftDetails | undefined;
  hospitalInfo: HospitalInfo | undefined;
  hospitalRatings: HospitalRatings | undefined;
  contractStatus: ContractStatus | undefined;
  isLoading: boolean;
  loading: Loading;
  errors: Errors;
  tab: Tab;
  shiftGroupId: number | undefined;
  drawer: "adjust" | "cancel" | undefined;
  hospitalId: number | undefined;
  promoId: number | undefined;
}

interface ShiftDetailsStoreActions {
  setShiftDetails: (shiftDetails?: ShiftDetails) => void;
  setHospitalInfo: (hospitalInfo?: HospitalInfo) => void;
  setHospitalRatings: (hospitalRatings?: HospitalRatings) => void;
  setContractStatus: (contractStatus?: ContractStatus) => void;
  setIsLoading: (isLoading: boolean) => void;
  setLoading: (loading: Loading) => void;
  setErrors: (errors: Errors) => void;
  setTab: (tab: Tab) => void;
  setDrawer: (drawer: "adjust" | "cancel" | undefined) => void;
  reset: () => void;
  setShiftGroupId: (shiftGroupId: number | undefined) => void;
  setHospitalId: (hospitalId: number | undefined) => void;
  setPromoId: (promoId: number | undefined) => void;
}

type ShiftDetailsStore = ShiftDetailsStoreState & ShiftDetailsStoreActions;

const initialState: ShiftDetailsStoreState = {
  shiftDetails: undefined,
  hospitalInfo: undefined,
  hospitalRatings: undefined,
  contractStatus: undefined,
  isLoading: false,
  loading: {
    shiftDetails: false,
    hospitalInfo: false,
    hospitalRatings: false,
    contractStatus: false,
  },
  errors: {
    shiftDetails: undefined,
    hospitalInfo: undefined,
    hospitalRatings: undefined,
    contractStatus: undefined,
  },
  tab: "info",
  shiftGroupId: undefined,
  drawer: undefined,
  hospitalId: undefined,
  promoId: undefined,
};

export const useShiftDetailsStore = create<ShiftDetailsStore>((set) => ({
  ...initialState,
  setShiftDetails: (shiftDetails) => set({ shiftDetails }),
  setHospitalInfo: (hospitalInfo) => set({ hospitalInfo }),
  setHospitalRatings: (hospitalRatings) => set({ hospitalRatings }),
  setContractStatus: (contractStatus) => set({ contractStatus }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setLoading: (loading) => set({ loading }),
  setErrors: (errors) => set({ errors }),
  setTab: (tab) => set({ tab }),
  setDrawer: (drawer) => set({ drawer }),
  reset: () => set(initialState),
  setShiftGroupId: (shiftGroupId) => set({ shiftGroupId }),
  setHospitalId: (hospitalId) => set({ hospitalId }),
  setPromoId: (promoId) => set({ promoId }),
}));
