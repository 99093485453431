import posthog from "posthog-js";
import React, { useEffect, useState } from "react";

import { useGetStates } from "../../../../api/utility/getStates";
import {
  EventVetSignUpForm,
  VetPreferredAreasOfPractice,
  createVetDataAccount,
} from "../../../../api/vet";
import { patchVet } from "../../../../api/vet/patchVet";
import { useVerifyLicenseData } from "../../../../api/vet/useVerifyLicenseData";
import {
  IMPROVED_FILTER_APPOINTMENT_TYPES,
  IMPROVED_PREFERRED_PROCEDURES,
  idToFieldMap,
} from "../../../../constants/checkBoxConstants";
import { VET_USER_TYPE } from "../../../../constants/UserTypeConstants";
import { loginRequestAction } from "../../../../Login/Action/LoginActions";
import { useAppDispatch } from "../../../../store/service";
import { Event, EventTracker } from "../../../../tracking/service/EventTracker/EventTrackerService";
import { OnVetSignedUp } from "../common/EventSignUp";
import { useValidate } from "../common/useValidate";
import { getUtmParams } from "../common/util";

import { FormProvider } from "./useFormContext";
import { VetSignUp } from "./VetSignUp";

export const VetSignUpContainer = ({
  booth,
  onSuccess,
  eventSlug,
}: {
  booth: boolean;
  onSuccess: OnVetSignedUp;
  eventSlug: string;
}) => {
  const [defaultForm, setDefaultForm] = useState<EventVetSignUpForm>();
  const dispatch = useAppDispatch();

  const { data: states } = useGetStates();

  const {
    utm_source: utmSource,
    utm_campaign: utmCampaign,
    utm_medium: utmMedium,
  } = getUtmParams(eventSlug, booth);

  const { validate } = useValidate();
  const { mutateAsync: verifyLicenseData } = useVerifyLicenseData();

  useEffect(() => {
    setDefaultForm({
      userTypeId: VET_USER_TYPE,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      zipcode: "",
      reenterZipcode: "",
      travelDistance: 35,
      licenseState: undefined,
      licenseNumber: "",
      preferredAreasOfPractice: IMPROVED_FILTER_APPOINTMENT_TYPES.map(({ id, label }) => ({
        id,
        label,
        checked: false,
      })),
      preferredProcedures: IMPROVED_PREFERRED_PROCEDURES.map((procedure) => ({
        id: procedure.id,
        label: procedure.label,
        name: "procedureTypeId",
        checked: false,
      })),
      utmSource,
      utmCampaign,
      utmMedium,
      typicalAvailableDays: [],
      currentSituation: undefined,
    });
  }, [utmSource, utmCampaign, utmMedium]);

  const save = async (form: EventVetSignUpForm) => {
    form.email = form.email.toLowerCase();
    const { signInRequest, ...result } = await createVetDataAccount(form);

    if (!result.success) {
      throw new Error(result.error);
    }

    EventTracker.send({
      eventName: Event.Name.VET_CONFERENCE_EVENT_REGISTRATION_COMPLETE,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.EVENT,
      entityId: undefined,
      context: { eventSlug },
    });

    posthog.setPersonPropertiesForFlags({ email: form.email });

    const vetPreferredAreasOfPractice: VetPreferredAreasOfPractice =
      form.preferredAreasOfPractice.reduce((acc, curr) => {
        const key = idToFieldMap[Number(curr.id)];
        if (key) {
          acc[key] = !!curr.checked;
        }
        return acc;
      }, {} as VetPreferredAreasOfPractice);

    const userId = parseInt(result.data.userId);
    const vetId = parseInt(result.data.vetId);

    await patchVet({
      userId,
      vetId,
      zipcode: form.zipcode,
      preferredProcedures: form.preferredProcedures
        .filter((pp) => pp.checked)
        .map((pp) => Number(pp.id)),
      preferredAreasOfPractice: true,
      currentSituation: form.currentSituation,
      ...vetPreferredAreasOfPractice,
    });

    if (typeof form.currentSituation === "number") {
      EventTracker.send({
        eventName: Event.Name.VET_SUBMIT_CURRENT_SITUATION,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.VET,
        entityId: vetId,
        context: { source: "eventRegistration" },
      });
    }

    const { licenseNumber, licenseState, zipcode } = form;
    const state = states?.find(({ id }) => id === licenseState)?.state_name;

    await verifyLicenseData({
      licenseNumber: licenseNumber ?? "",
      userId: userId,
      zipcode: zipcode ?? "",
      state: state ?? "",
      isFromWeb: true,
    });

    if (booth) {
      return onSuccess({ ...result.data });
    }

    const { email, password } = form;
    dispatch(loginRequestAction({ email, password, signInRequest }));
  };

  return (
    <FormProvider
      defaultForm={defaultForm!}
      save={save}
      validate={(form: EventVetSignUpForm) => validate({ providerType: "vet", form })}
    >
      <VetSignUp booth={booth} eventSlug={eventSlug} />
    </FormProvider>
  );
};
