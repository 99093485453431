import { Button, FlexLayout, Link, MediumText, Modal, SmallText, grayWhite } from "@RooUI";
import React from "react";

import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

import { RooIcon } from "../RooIcon";

import { ClaimPromoModal } from "./ClaimPromoModal";
import { ClaimPromoCardContainer, IconContainer, PromoContent } from "./styles";
import { usePromoManagement } from "./usePromoManagement";

export const ClaimPromoCard = () => {
  const {
    showModal,
    showLoading,
    claimPromoMutation,
    eligiblePromos,
    handleOpenModal,
    handleDismiss,
    setShowModal,
  } = usePromoManagement();
  const { userId } = useRequiredAuthorizedUser();
  const currentPromo = eligiblePromos?.[0];

  if (!currentPromo) return null;

  return (
    <>
      <Modal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
        $tabNameArr={[
          {
            index: 1,
            name: currentPromo.promoName,
            panelContent: (
              <ClaimPromoModal
                promo={currentPromo}
                showLoading={showLoading}
                onClaim={() =>
                  claimPromoMutation.mutate({
                    userId,
                    promoId: currentPromo.promoId,
                  })
                }
                onClose={() => setShowModal(false)}
              />
            ),
          },
        ]}
        defaultSelectedTabIndex={1}
      />
      <ClaimPromoCardContainer data-testid={`promo${currentPromo.promoId}`}>
        <PromoContent>
          <MediumText>{currentPromo.promoName}</MediumText>
          <SmallText>{currentPromo.promoBannerAndEmailText}</SmallText>
          <FlexLayout gap="m">
            <Button
              data-testid={`reviewPromo${currentPromo.promoId}`}
              onClick={() => handleOpenModal(currentPromo.promoId)}
            >
              Review Promo
            </Button>
            <Link onClick={() => handleDismiss(currentPromo.promoId)}>No thanks</Link>
          </FlexLayout>
        </PromoContent>
        <div>
          <IconContainer>
            <RooIcon icon="promo" size="l" color={grayWhite} />
          </IconContainer>
        </div>
      </ClaimPromoCardContainer>
    </>
  );
};
