// Registration Hospital constanst

export const INITIALIZE_LOGIN_STATE = "INITIALIZE_LOGIN_STATE";
export const INITIALIZE_REGISTER_HOSPITAL_STATE = "INITIALIZE_REGISTER_HOSPITAL_STATE";

export const CREATE_HOSPITAL_ACCOUNT_REQUEST = "CREATE_HOSPITAL_ACCOUNT_REQUEST";
export const CREATE_HOSPITAL_ACCOUNT_SUCCESS = "CREATE_HOSPITAL_ACCOUNT_SUCCESS";
export const CREATE_HOSPITAL_ACCOUNT_FAIL = "CREATE_HOSPITAL_ACCOUNT_FAIL";

// export const HOSPITAL_DETAILS_CHANGE_REQUEST = "HOSPITAL_DETAILS_CHANGE_REQUEST";
// export const HOSPITAL_DETAILS_CHANGE_SUCCESS = "HOSPITAL_DETAILS_CHANGE_SUCCESS";

export const FETCH_HOSPITAL_BASIC_INFO = "FETCH_HOSPITAL_BASIC_INFO";
export const FETCH_HOSPITAL_BASIC_INFO_SUCCESS = "FETCH_HOSPITAL_BASIC_INFO_SUCCESS";
export const FETCH_HOSPITAL_BASIC_INFO_FAIL = "FETCH_HOSPITAL_BASIC_INFO_FAIL";

export const SAVE_HOSPITAL_BASIC_INFO = "SAVE_HOSPITAL_BASIC_INFO";
export const SAVE_HOSPITAL_BASIC_INFO_SUCCESS = "SAVE_HOSPITAL_BASIC_INFO_SUCCESS";
export const SAVE_HOSPITAL_BASIC_INFO_FAIL = "SAVE_HOSPITAL_BASIC_INFO_FAIL";

export const FETCH_HOSPITAL_DATA_PAGE = "FETCH_HOSPITAL_DATA_PAGE";
export const FETCH_HOSPITAL_DATA_PAGE_SUCCESS = "FETCH_HOSPITAL_DATA_PAGE_SUCCESS";
export const FETCH_HOSPITAL_DATA_PAGE_FAIL = "FETCH_HOSPITAL_DATA_PAGE_FAIL";

export const SAVE_HOSPITAL_DATA_PAGE = "SAVE_HOSPITAL_DATA_PAGE";
export const SAVE_HOSPITAL_DATA_PAGE_SUCCESS = "SAVE_HOSPITAL_DATA_PAGE_SUCCESS";
export const SAVE_HOSPITAL_DATA_PAGE_FAIL = "SAVE_HOSPITAL_DATA_PAGE_FAIL";

export const FETCH_HOSPITAL_GET_TO_KNOW = "FETCH_HOSPITAL_GET_TO_KNOW";
export const FETCH_HOSPITAL_GET_TO_KNOW_SUCCESS = "FETCH_HOSPITAL_GET_TO_KNOW_SUCCESS";
export const FETCH_HOSPITAL_GET_TO_KNOW_FAIL = "FETCH_HOSPITAL_GET_TO_KNOW_FAIL";

export const SAVE_HOSPITAL_GET_TO_KNOW = "SAVE_HOSPITAL_GET_TO_KNOW";
export const SAVE_HOSPITAL_GET_TO_KNOW_SUCCESS = "SAVE_HOSPITAL_GET_TO_KNOW_SUCCESS";
export const SAVE_HOSPITAL_GET_TO_KNOW_FAIL = "SAVE_HOSPITAL_GET_TO_KNOW_FAIL";

export const FETCH_HOSPITAL_PHOTO_PAGE = "FETCH_HOSPITAL_PHOTO_PAGE";
export const FETCH_HOSPITAL_PHOTO_PAGE_SUCCESS = "FETCH_HOSPITAL_PHOTO_PAGE_SUCCESS";
export const FETCH_HOSPITAL_PHOTO_PAGE_FAIL = "FETCH_HOSPITAL_PHOTO_PAGE_FAIL";

export const UPLOAD_HOSPITAL_IMAGES_REQUEST = "UPLOAD_HOSPITAL_IMAGES_REQUEST";
export const UPLOAD_HOSPITAL_IMAGES_SUCCESS = "UPLOAD_HOSPITAL_IMAGES_SUCCESS";
export const UPLOAD_HOSPITAL_IMAGES_FAIL = "UPLOAD_HOSPITAL_IMAGES_FAIL";

export const CREATE_HOSPITAL_PROFILE_REQUEST = "CREATE_HOSPITAL_PROFILE_REQUEST";
export const CREATE_HOSPITAL_PROFILE_SUCCESS = "CREATE_HOSPITAL_PROFILE_SUCCESS";
export const CREATE_HOSPITAL_PROFILE_FAIL = "CREATE_HOSPITAL_PROFILE_FAIL";

export const SIGN_UP_REQUESTED = "SIGN_UP_REQUESTED";
export const FETCH_STATES_REQUEST = "FETCH_STATES_REQUEST";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_FAIL = "FETCH_STATES_FAIL";

export const FETCH_COMMON_DATA = "FETCH_COMMON_DATA";
export const FETCH_COMMON_DATA_SUCCESS = "FETCH_COMMON_DATA_SUCCESS";
export const FETCH_COMMON_DATA_FAIL = "FETCH_COMMON_DATA_FAIL";

export const DELETE_HOSPITAL_IMAGE_REQUEST = "DELETE_HOSPITAL_IMAGE_REQUEST";
export const DELETE_HOSPITAL_IMAGE_SUCCESS = "DELETE_HOSPITAL_IMAGE_SUCCESS";
export const DELETE_HOSPITAL_IMAGE_FAIL = "DELETE_HOSPITAL_IMAGE_FAIL";

export const GET_DECODED_VALUES_REQUEST = "GET_DECODED_VALUES_REQUEST";
export const GET_DECODED_VALUES_SUCCESS = "GET_DECODED_VALUES_SUCCESS";
export const GET_DECODED_VALUES_FAIL = "GET_DECODED_VALUES_FAIL";

export const SAVE_HOSPITAL_DATA_REDUCER = "SAVE_HOSPITAL_DATA_REDUCER";
export const FETCH_HOSPITAL_DATA_REDUCER = "FETCH_HOSPITAL_DATA_REDUCER";

export const SAVE_GET_TO_KNOW_REDUCER = "SAVE_GET_TO_KNOW_REDUCER";
export const FETCH_GET_TO_KNOW_REDUCER = "FETCH_GET_TO_KNOW_REDUCER";

export const SAVE_PHOTO_REDUCER = "SAVE_PHOTO_REDUCER";
export const FETCH_PHOTO_REDUCER = "FETCH_PHOTO_REDUCER";

export const UPLOAD_HOSPITAL_501C3_LETTER_REQUEST = "UPLOAD_HOSPITAL_501C3_LETTER_REQUEST";
export const UPLOAD_HOSPITAL_501C3_LETTER_SUCCESS = "UPLOAD_HOSPITAL_501C3_LETTER_SUCCESS";
export const UPLOAD_HOSPITAL_501C3_LETTER_FAIL = "UPLOAD_HOSPITAL_501C3_LETTER_FAIL";

export const DELETE_HOSPITAL_501C3_LETTER_REQUEST = "DELETE_HOSPITAL_501C3_LETTER_REQUEST";
export const DELETE_HOSPITAL_501C3_LETTER_SUCCESS = "DELETE_HOSPITAL_501C3_LETTER_SUCCESS";
export const DELETE_HOSPITAL_501C3_LETTER_FAIL = "DELETE_HOSPITAL_501C3_LETTER_FAIL";

export const CLEAR_LOCAL_STORAGE = "CLEAR_LOCAL_STORAGE";
export const UPDATE_HOSPITAL_IMAGES_SUCCESS = "UPDATE_HOSPITAL_IMAGES_SUCCESS";

export const SET_PROFILE_IMAGE = "SET_PROFILE_IMAGE";

export const MASK = {
  MOBILE: "(###) ###-####",
  DOB: "##/##/####",
  SSN: "###-##-####",
  EIN: "##-#######",
  DOB_WITHOUT_SLASH: "##-##-####",
  AMOUNT_OF_POSITIONS_FOR_EVENT: "###",
};

export const SHIFT_COUNT_FOR_CORE_VET = 12;
export const SHIFT_COUNT_FOR_CORE_TECH = 12;
export const CORE_VET_PLACEMENT_FEE = 20000;
export const VET_PLACEMENT_FEE = 10000;
export const CORE_TECH_PLACEMENT_FEE = 2000;
export const TECH_PLACEMENT_FEE = 1000;

export const techRegistrationFlows = {
  Lenient: "Lenient",
  Strict: "Strict",
  CaliforniaFlow: "CaliforniaFlow",
  TexasFlow: "TexasFlow",
} as const;

export const newVetMarketsReleaseDate = "2022-06-07";

export const ICContractDefaultSelection = {
  exhibitA: 2,
  exhibitB: 1,
};

export const testimonialSliderSettings = {
  centerMode: true,
  centerPadding: "0px",
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        swipe: true,
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
        initialSlide: 2,
      },
    },
  ],
};

export const MaxAllowedUserToBlockBy = {
  vet: 5,
  tech: 5,
  hospital: {
    vet: 10,
    tech: 20,
  },
};

export const NumOfAllowedUserToBlock = {
  Vet: 10,
  Tech: 10,
  Hospital: 10,
};

export const BATCH_UPLOAD_FILE_HEADERS = {
  hospitalName: "Hospital Name",
  streetAddress: "Street Address",
  city: "City",
  state: "State",
  zip: "Zip",
  hospitalType: "Hospital type",
  hospitalGroup: "Group",
  primaryContactName: "Primary Contact Name",
  primaryContactNumber: "Primary Contact Number",
  primaryContactEmail: "Primary Contact Email",
  hospitalPhoneNumber: "Hospital Phone Number",
  averageNoOfDVMWorkPerDay: "# of Associate Vets",
  hospitalMaintainRecords: "Record Maintenance",
  practiceManagementSoftware: "PMS Used",
  hospitalDressCodes: "Dress Code",
};

export const HOLIDAY_LABELS = {
  christmas: "Christmas Day",
  new_year: "New Year’s Day",
  labor_day: "Labor Day",
  independence_day: "Independence Day",
  memorial_day: "Memorial Day",
  thanksgivingDay: "Thanksgiving Day",
  juneteenth: "Juneteenth National Independence Day",
  presidentsDay: `Presidents’ Day`,
  columbusDay: "Indigenous Peoples’ Day",
  mlkDay: `Martin Luther King’s Birthday`,
  veteransDay: `Veterans’ Day`,
};

export const invoicedLaunchDate = "2022-12-20";
export const SRP_ENABLED_METROS = [1, 11];

export const ROO_MAIL = "hello@roo.vet";
export const FULL_TIME_PLACEMENT_MAIL = "fulltimeplacement@roo.vet";

// these apply to any boost input that is not Set Your Price
export const MIN_SHIFT_BOOST_AMOUNT = 1;
export const MAX_SHIFT_BOOST_AMOUNT = 9999;

export const UPDATE_IS_DISABLE_ADD_SHIFT = "UPDATE_IS_DISABLE_ADD_SHIFT";

export const STRIPE_SETUP_INTENT_STATUS = {
  CANCELED: "canceled",
  CREATED: "created",
  REQUIRES_ACTION: "requires_action",
  SETUP_FAILED: "setup_failed",
  SUCCEEDED: "succeeded",
};

export const IS_REQUIRE_DEA_LICENSE = "is-require-dea-license";

export const UTM_SOURCE_FALLBACK = "manual";
export const DEFAULT_UTM_SOURCE = "admin";
