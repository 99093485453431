import { useMutation } from "@tanstack/react-query";

import { expressInterestInPromoProposal } from "../../constants/apiRoutes";
import { APIError, post } from "../../api/common/rooFetch";

interface ExpressInterestInPromoProposalParams {
  promoProposalId: number;
}

export const useExpressInterestInPromoProposal = (options?: {
  onSuccess?: () => void;
  onMutate?: () => void;
}) => {
  return useMutation<void, APIError<{ message: string }>, ExpressInterestInPromoProposalParams>(
    (params) => {
      return post(expressInterestInPromoProposal(params.promoProposalId), {});
    },
    {
      onSuccess: () => {
        options?.onSuccess?.();
      },
      onMutate: () => {
        options?.onMutate?.();
      },
    }
  );
};
