import { useContactOwner } from "@Roo/api/vet/useContactOwner";
import {
  Avatar,
  blueDark600,
  BodyText,
  disabledTextColor,
  FlexLayout,
  greenBase,
  Icon,
  MediumText,
  SmallText,
} from "@RooUI";
import React from "react";
import styled from "styled-components";

import fallbackAvatar from "../../static/images/fallback_avatar.svg";
import { useRooTranslation } from "../Wrappers/useRooTranslation";

interface MeetYourRooRepProps {
  vetId: number;
  marginBottom?: string;
  questionsAboutRooText?: string;
}
export const MeetYourRooRep = ({
  vetId,
  questionsAboutRooText,
  marginBottom = "32px",
}: MeetYourRooRepProps) => {
  const { data } = useContactOwner({ vetId });
  const { firstName, lastName, photo } = data?.data || {};
  const { t } = useRooTranslation();

  const callText =
    firstName && lastName
      ? `👋 Your call will be with ${firstName} ${lastName} — your local Roo Representative`
      : `👋 Your call will be with your local Roo Representative`;

  const section1Title = t("registration.meetYourRooRep.section1.title");
  const section1Description = t("registration.meetYourRooRep.section1.description");
  const section2Title = t("registration.meetYourRooRep.section2.title");
  const section2Description = questionsAboutRooText
    ? questionsAboutRooText
    : t("registration.meetYourRooRep.section2.description");
  const section2Icon = "SoundDetectionDogBarkingRound";

  return (
    <FlexLayout vertical gap="xxl" style={{ marginBottom: marginBottom }}>
      {/* What will happen on the call */}
      <FlexLayout vertical gap="l">
        <FlexLayout align="start" gap="m">
          <Icon name="StethoscopeOutlined" color={greenBase} />
          <FlexLayout vertical gap="xs">
            <MediumText color={blueDark600}>{section1Title}</MediumText>
            <Text>{section1Description}</Text>
          </FlexLayout>
        </FlexLayout>
        <FlexLayout align="start" gap="m">
          <Icon name={section2Icon} color={greenBase} />
          <FlexLayout vertical gap="xs">
            <MediumText color={blueDark600}>{section2Title}</MediumText>
            <Text>{section2Description}</Text>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      {/* Roo rep */}
      <FlexLayout align="center" gap="m" className="border-top pt-2" data-testid="rooRep">
        {photo ? <Avatar size={64} shape="circle" src={photo} /> : <RooLogo src={fallbackAvatar} />}
        <StyledSmallText color={disabledTextColor} data-testid="localRepresentative">
          {callText}
        </StyledSmallText>
      </FlexLayout>
    </FlexLayout>
  );
};

const Text = styled.div`
  ${BodyText}
`;

const RooLogo = styled.img`
  height: 64px;
  width: 64px;
`;

const StyledSmallText = styled(SmallText)`
  flex: 1;
`;
