import React from "react";

import { GroupedShiftList, UngroupedShiftList } from "@RooBeta/types";

import { EmptyList } from "./EmptyList";
import { FlatShiftList as FlatShiftListComponent } from "./FlatShiftList";
import { GroupedShiftList as GroupedShiftListComponent } from "./GroupedShiftList";

type ShiftListProps = {
  shiftList: GroupedShiftList | UngroupedShiftList;
  fixedHeight?: boolean;
  page?: string;
};

export const ShiftList = ({ shiftList, fixedHeight = true, page }: ShiftListProps) => {
  if (shiftList.count === 0) {
    return <EmptyList />;
  } else if (shiftList.format === "grouped") {
    return (
      <GroupedShiftListComponent shiftList={shiftList} fixedHeight={fixedHeight} page={page} />
    );
  } else {
    return <FlatShiftListComponent shiftList={shiftList} fixedHeight={fixedHeight} />;
  }
};
