import React from "react";

import { blueDark600, disabledTextColor, redBase } from "../../styles/constants";

import { FormLabel as StyledFormLabel } from "./FormLabelStyles";

export interface LabelProps {
  children?: React.ReactNode;
  error?: boolean;
  $disabled?: boolean;
  htmlFor?: string;
  $color?: string;
}

export const FormLabel: React.FC<LabelProps> = (props) => {
  const { error, $disabled, children, htmlFor, $color } = props;

  return (
    <StyledFormLabel $color={$color ?? getFormLabelColor(error, $disabled)} htmlFor={htmlFor}>
      {children}
    </StyledFormLabel>
  );
};

const getFormLabelColor = (error?: boolean, disabled?: boolean) => {
  let color = blueDark600;

  if (error) {
    color = redBase;
  } else if (disabled) {
    color = disabledTextColor;
  }

  return color;
};
