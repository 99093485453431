import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ChatNotifications } from "@Roo/Common/ChatNotifications/ChatNotifications";
import { HStack, RooIcon } from "@RooBeta/components";
import { useRequiredAuthorizedUser, useRoutes, useTracking, useViewportSize } from "@RooBeta/hooks";
import { useViewport } from "@RooBeta/store";
import { Icon, ViewportSize } from "@RooBeta/types";

import { Logo } from "./Logo";
import {
  NavigationContainer,
  NavigationItem,
  NavigationItems,
  NavigationWrapper,
  ToggleIcon,
} from "./styles";
import { getTrackingOptions } from "./util";
import { ContractorAccountDropDown } from "./ContractorAccountDropDown";
import { ContractorNavigationDrawer } from "./ContractorNavigationDrawer";

export const Navigation = () => {
  // Populates the viewport size in the store.
  useViewportSize({ debounceDelay: 50 });

  const viewport = useViewport();
  const [isOpen, setIsOpen] = useState(false);

  const {
    provider: { providerType },
  } = useRequiredAuthorizedUser();

  const { pathname } = useLocation();
  const routes = useRoutes();

  const withTracking = useTracking({ providerType });

  const renderNavigationLink = (to: string, label: string, icon: Icon) => {
    const active = pathname === to;
    return (
      <Link
        to={to}
        onClick={withTracking(() => {}, getTrackingOptions(label))}
        data-testid={`${label.toLowerCase()}Nav`}
      >
        <NavigationItem $active={active} data-active={active}>
          <RooIcon icon={icon} size="l" /> {label}
        </NavigationItem>
      </Link>
    );
  };

  return (
    <NavigationContainer data-testid="navigation">
      <NavigationWrapper>
        <Logo />
        {viewport >= ViewportSize.m ? (
          <NavigationItems $viewport={viewport}>
            {renderNavigationLink(routes.SCHEDULE, "Schedule", "calendar_month")}
            {renderNavigationLink(routes.SHIFTS, "Shifts", "search")}
            {renderNavigationLink(routes.EARNINGS, "Earnings", "payment")}
            <ChatNotifications>
              <NavigationItem>
                <RooIcon icon="notifications" size="l" />
                Notifications
              </NavigationItem>
            </ChatNotifications>
            <ContractorAccountDropDown setIsOpen={setIsOpen} isOpen={isOpen}>
              <NavigationItem>
                <RooIcon icon="account" size="l" />
                <HStack>
                  <div>Account</div>
                  <ToggleIcon icon="expand_more" $open={isOpen} />
                </HStack>
              </NavigationItem>
            </ContractorAccountDropDown>
          </NavigationItems>
        ) : (
          <NavigationItems $viewport={viewport}>
            <ChatNotifications>
              <NavigationItem $icon>
                <RooIcon icon="notifications" size="xl" />
              </NavigationItem>
            </ChatNotifications>
            <ContractorNavigationDrawer setIsOpen={setIsOpen} isOpen={isOpen}>
              <NavigationItem $icon>
                <RooIcon icon="menu" size="xl" />
              </NavigationItem>
            </ContractorNavigationDrawer>
          </NavigationItems>
        )}
      </NavigationWrapper>
    </NavigationContainer>
  );
};
