import {
  Button,
  Input,
  Link,
  MediumText,
  Modal,
  SmallText,
  Stepper,
  StepperContent,
  StepperNext,
  StepperPrevious,
  StepperStep,
  Text,
} from "@RooUI";
import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

import { submitContract } from "../../api/contracts";
import { ControlledInput, ControlledPhoneInput } from "../../Common/Controllers";
import { useStates } from "../../Common/Form/hooks/useStates";
import { showToast } from "../../Common/Toasts/action/ToastActions";
import { openZendeskChat } from "../../Common/zendeskUtils";
import * as UserTypes from "../../constants/UserTypeConstants";
import { AuthUtility } from "../../helpers/AuthUtility";
import { RequestShiftFormData } from "../../HospitalProfilePanels/types";
import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";
import { closeNewHospitalProfilePanel } from "../actions";

import BanfieldLogo from "./Logos/Banfield.png";
import BluePearlLogo from "./Logos/BluePearl.png";
import { MarsContract } from "./MarsContract";
import {
  NDAModalContainer,
  ScrollArea,
  StepperContentContainer,
  StyledStepperSteps,
} from "./styles";

const MARS_EMAIL = "CommercialTeam@banfield.com";

export interface NDAFormData {
  date: string;
  fullName: string;
  firstName: string;
  lastName: string;
  homeAddress: string;
  phoneNumber: string;
}

export interface MarsNDAModalProps {
  handleRequestSubmit?: (data: RequestShiftFormData) => void;
  getRequestShiftFormData?: () => RequestShiftFormData;
  isReadOnly?: boolean;
  readOnlyValues?: {
    fullName: string;
    homeAddress: string;
    phoneNumber: string;
    date: string;
  };
  isHospitalView?: boolean;
  onClose?: () => void;
  isShowModal?: boolean;
  contractId?: number | null;
  onSubmitSignature?: () => void;
  shiftId?: number | null;
  isInstantBookable?: boolean;
}

export const MarsNDAModal = ({
  handleRequestSubmit,
  getRequestShiftFormData,
  isReadOnly = false,
  readOnlyValues,
  isHospitalView = false,
  onClose,
  isShowModal,
  contractId,
  onSubmitSignature,
  shiftId,
  isInstantBookable,
}: MarsNDAModalProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<NDAFormData>({
    defaultValues: {
      date: "",
      fullName: "",
      firstName: "",
      lastName: "",
      homeAddress: "",
      phoneNumber: "",
    },
    mode: "onBlur",
  });

  const dispatch = useDispatch();

  const { stateOptions } = useStates();

  const login = useSelector((state: RootState) => state.login);
  const { address, city, stateId, zipcode, phoneNumber, userId, vetId } = login;
  const stateCode = stateOptions.find((state) => state.id === stateId)?.stateCode;
  const addressString =
    address && city && stateCode && zipcode ? `${address}, ${city}, ${stateCode}, ${zipcode}` : "";

  const [isSuperUser, setIsSuperUser] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { firstName, lastName, homeAddress, phoneNumber: formPhoneNumber } = watch();
  const [stepperStep, setStepperStep] = useState<number>(0);

  const eventEntityType = shiftId ? Event.Entity.VET_SHIFT : Event.Entity.VET;
  const eventEntityId = shiftId ? shiftId : vetId;

  const onCloseModal = (source: string, step: number) => {
    setStepperStep(0);
    onClose?.();
    EventTracker.send({
      eventName:
        step === 0 ? Event.Name.OVERVIEW_AGREEMENT_DISMISS : Event.Name.EXIT_AGREEMENT_MODAL,
      eventType: Event.Type.CLICK,
      entityType: eventEntityType,
      entityId: eventEntityId,
      context: {
        source,
        marsNDAFlowV2: true,
      },
    });
  };

  useEffect(() => {
    setValue("date", moment(new Date()).format("MMM. D, YYYY"));
    getIsSuperUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isReadOnly) {
      if (readOnlyValues?.fullName) {
        const [extractedFirstName, ...lastNameParts] = readOnlyValues.fullName.split(" ");
        setValue("fullName", readOnlyValues.fullName);
        setValue("firstName", extractedFirstName);
        setValue("lastName", lastNameParts.join(" "));
        setValue("homeAddress", readOnlyValues.homeAddress);
        setValue("phoneNumber", readOnlyValues.phoneNumber);
        setValue("date", moment(readOnlyValues.date).format("MMM. D, YYYY"));
      }
    } else {
      setValue("homeAddress", addressString);
      setValue("phoneNumber", phoneNumber);
    }
  }, [addressString, phoneNumber, isReadOnly, readOnlyValues, setValue]);

  const sendEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
  };

  const handleOpenZendeskChat = () => {
    onCloseModal("contactRoo", stepperStep);
    dispatch(closeNewHospitalProfilePanel());
    openZendeskChat();
  };

  const onSubmit = (data: NDAFormData) => {
    if (isReadOnly || isSubmitting) return;
    setIsSubmitting(true);

    const shiftFormData = getRequestShiftFormData?.();

    const submitContractPromise = contractId
      ? submitContract(userId, contractId, data.fullName, data.homeAddress, data.phoneNumber)
      : Promise.reject(new Error("No contractId provided"));

    submitContractPromise
      // eslint-disable-next-line promise/prefer-await-to-then
      .then(() => {
        EventTracker.send({
          eventName: Event.Name.SIGN_AGREEMENT,
          eventType: Event.Type.CLICK,
          entityType: eventEntityType,
          entityId: eventEntityId,
          context: {
            marsNDAFlowV2: true,
          },
        });
        if (onSubmitSignature) onSubmitSignature();
        if (shiftFormData && handleRequestSubmit) {
          handleRequestSubmit(shiftFormData);
        }
        setStepperStep(0);
      })
      .catch((error) => {
        Sentry.captureException(new Error(`Error submitting NDA contract: ${error.message}`), {
          extra: {
            contractId,
            userId,
            fullName: data.fullName,
            homeAddress: data.homeAddress,
          },
        });
        dispatch(
          showToast({
            variant: "error",
            message: "Error",
            description: "Failed to submit agreement.",
          })
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const getIsSuperUser = async () => {
    const authContext = await AuthUtility.getAuthContext();
    setIsSuperUser(authContext?.userTypeId === UserTypes.ROO_SUPER_USER_TYPE);
  };

  const getSubmitButtonText = () => {
    if (!handleRequestSubmit) return "Sign";
    return isInstantBookable ? "Sign & Book Now" : "Sign & Request";
  };

  return (
    <Modal
      showModal={isShowModal ?? false}
      onCloseModal={() => onCloseModal("xBtn", stepperStep)}
      header="" // intentionally left blank
      $size="l"
      $noPadding
      body={
        <NDAModalContainer
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="header-container" data-testid="marsHeaderContainer">
            <SmallText>Mars Veterinary Health</SmallText>
            <div className="logo-container">
              <img src={BluePearlLogo} alt="BluePearl Logo" />
              <img src={BanfieldLogo} alt="Banfield Logo" />
            </div>
          </div>

          <Stepper count={2} step={stepperStep} onStepChange={setStepperStep}>
            <StyledStepperSteps>
              <StepperStep step={0} data-testid="marsOverviewStep">
                Overview
              </StepperStep>
              <StepperStep step={1} data-testid="marsReviewAgreementStep">
                Review Agreement
              </StepperStep>
            </StyledStepperSteps>

            <StepperContent step={0}>
              <StepperContentContainer vertical gap="xxl">
                <Text data-testid="marsOverviewText1">
                  This agreement does NOT restrict you from working elsewhere, make you an employee
                  of MVH, or limit professional communication with MVH clients. We’re excited to
                  have you join Mars Veterinary Health (MVH)!
                </Text>
                <Text data-testid="marsOverviewText2">
                  This agreement between you and MVH, requires you as an independent contractor to
                  (1) protect MVH confidential information, (2) not take business away from MVH by
                  soliciting our existing clients, (3) not work overtime without hospital approval,
                  and (4) to maintain appropriate licensure.
                </Text>
                <div className="button-container">
                  <StepperNext
                    size="small"
                    data-testid="marsOverviewContinueButton"
                    onClick={() => {
                      EventTracker.send({
                        eventName: Event.Name.OVERVIEW_AGREEMENT_CONTINUE,
                        eventType: Event.Type.CLICK,
                        entityType: eventEntityType,
                        entityId: eventEntityId,
                        context: {
                          marsNDAFlowV2: true,
                        },
                      });
                    }}
                  />
                </div>
              </StepperContentContainer>
            </StepperContent>

            <StepperContent step={1}>
              <StepperContentContainer vertical gap="xxl">
                {!isReadOnly && (
                  <>
                    <div className="text-container">
                      <Text data-testid="marsHeaderText">
                        Starting 10/30/24, Mars Veterinary Health Group is requiring vets to
                        digitally sign the agreement below to request relief shifts at Banfield or
                        BluePearl hospitals (not required for other hospitals).{" "}
                        <MediumText>
                          This is not a Roo requirement.{" "}
                          <Link
                            href="https://5920186.hs-sites.com/mars-vsa-faq"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              EventTracker.send({
                                eventName: Event.Name.REVIEW_AGREEMENT_LEARN_MORE,
                                eventType: Event.Type.CLICK,
                                entityType: eventEntityType,
                                entityId: eventEntityId,
                                context: {
                                  marsNDAFlowV2: true,
                                },
                              });
                            }}
                          >
                            Learn more
                          </Link>
                        </MediumText>
                        .
                      </Text>
                    </div>
                    <div className="text-container">
                      <Text>
                        Questions about the agreement? Contact{" "}
                        <Link onClick={() => sendEmail(MARS_EMAIL)}>{MARS_EMAIL}</Link>. For other
                        questions please, <Link onClick={handleOpenZendeskChat}>contact Roo</Link>.
                      </Text>
                    </div>
                  </>
                )}
                <ScrollArea data-testid="marsContract">
                  <MarsContract isHospitalView={isHospitalView} vetId={vetId} />
                </ScrollArea>
                {!isReadOnly && (
                  <Text data-testid="marsContractText">
                    To request Banfield or BluePearl shifts, please fill in the fields below and
                    confirm your personal info to sign Mars group's agreement and submit your shift
                    request. You only need to sign this once.
                  </Text>
                )}
                <div className="input-container">
                  <ControlledInput
                    label="First name"
                    name="firstName"
                    control={control}
                    rules={{
                      required: "Required",
                      validate: (value) =>
                        value.length <= 255 || "First name must be 255 characters or less",
                      onChange: (e) => {
                        const newFirstName = e.target.value.trim();
                        setValue("fullName", `${newFirstName} ${lastName}`.trim());
                      },
                    }}
                    error={errors.firstName?.message}
                    disabled={isReadOnly || isSuperUser}
                    data-testid="marsFirstName"
                  />
                  <ControlledInput
                    label="Last name"
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Required",
                      validate: (value) =>
                        value.length <= 255 || "Last name must be 255 characters or less",
                      onChange: (e) => {
                        const newLastName = e.target.value.trim();
                        setValue("fullName", `${firstName} ${newLastName}`.trim());
                      },
                    }}
                    error={errors.lastName?.message}
                    disabled={isReadOnly || isSuperUser}
                    data-testid="marsLastName"
                  />
                </div>
                <ControlledInput
                  label="Home address"
                  name="homeAddress"
                  control={control}
                  rules={{
                    required: "Required",
                    validate: (value) =>
                      value.length <= 400 || "Home address must be 400 characters or less",
                  }}
                  error={errors.homeAddress?.message}
                  disabled={isReadOnly || isSuperUser}
                  data-testid="marsHomeAddress"
                />
                <div className="input-container">
                  <ControlledPhoneInput
                    label="Phone number"
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: "Required",
                      validate: (value) => {
                        const digitsOnly = value.replace(/\D/g, "");
                        return digitsOnly.length === 10 || "Phone number must be 10 digits";
                      },
                    }}
                    error={errors.phoneNumber?.message}
                    disabled={isReadOnly || isSuperUser}
                    data-testid="marsPhoneNumber"
                  />
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <Input label="Date" disabled {...field} data-testid="marsDate" />
                    )}
                  />
                </div>
                <div className="button-container">
                  <StepperPrevious
                    size="small"
                    onClick={() => {
                      EventTracker.send({
                        eventName: Event.Name.REVIEW_AGREEMENT_BACK,
                        eventType: Event.Type.CLICK,
                        entityType: eventEntityType,
                        entityId: eventEntityId,
                        context: {
                          marsNDAFlowV2: true,
                        },
                      });
                    }}
                  />
                  {isReadOnly ? (
                    <Button
                      $buttonType="primary"
                      onClick={() => onCloseModal("closeBtn", stepperStep)}
                      size="small"
                    >
                      Done
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        !firstName ||
                        firstName.trim().length < 2 ||
                        !lastName ||
                        lastName.trim().length < 2 ||
                        !homeAddress ||
                        homeAddress.trim().length < 3 ||
                        !formPhoneNumber ||
                        formPhoneNumber.length < 10 ||
                        isSuperUser ||
                        isSubmitting
                      }
                      $buttonType="primary"
                      type="submit"
                      size="small"
                      data-testid="marsSignAndRequestButton"
                    >
                      {getSubmitButtonText()}
                    </Button>
                  )}
                </div>
              </StepperContentContainer>
            </StepperContent>
          </Stepper>
        </NDAModalContainer>
      }
    />
  );
};
