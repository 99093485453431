import styled from "styled-components";
import { FlexLayout, spacingXS, spacingM, spacingS, spacingXXL } from "@RooUI";

export const ImageContainer = styled(FlexLayout)`
  padding-top: ${spacingXXL};
  padding-bottom: ${spacingM};
`;

export const CheckboxContainer = styled(FlexLayout)`
  padding-top: ${spacingXS};
`;

export const ConfirmButton = styled(FlexLayout)`
  margin-top: ${spacingS};
  align-self: center;
`;
