import { useGetUserPromos } from "@Roo/hooks/api/useGetUserPromos";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";
import { TOAST_PRIORITIES, useToastStore } from "@RooBeta/store/useToastStore";
import { Link, spacing, SmallMediumBodyText, SmallBodyText } from "@RooUI";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PromoPickerModal } from "../PromoPickerModal";
import { styled } from "styled-components";

const LinkStyled = styled(Link)`
  padding-left: ${spacing.xs};
`;

const Title = styled.div`
  ${SmallMediumBodyText}
`;

const TextStyled = styled.div`
  ${SmallBodyText}
`;

export const PromoToast = () => {
  const { promoId, shiftGroupId, setPromoId } = useShiftDetailsStore();
  const { userId, vetId } = useRequiredAuthorizedUser();
  const { show, isVisible } = useToastStore();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { promos, isFetched } = useGetUserPromos(userId, {
    isVet: !!vetId,
    requestPeriodActive: true,
    shiftGroupId: shiftGroupId ?? undefined,
  });

  useEffect(() => {
    if (!promoId && promos?.started?.length) {
      const promo = promos?.started?.[0];
      setPromoId(promo.promoId);
    }
  }, [promoId, promos?.started, setPromoId, show]);

  useEffect(() => {
    if (promoId && isFetched) {
      const promo = promos?.started?.find((p) => p.promoId === promoId);
      if (promo) {
        show({
          message: (
            <TextStyled>
              Complete {promo.numberOfShifts} shifts by{" "}
              {moment(promo.shiftCompleteByDate).format("MM/DD")} to get an extra $
              {promo.promoAmount}!
            </TextStyled>
          ),
          variant: "success",
          region: "panel",
          icon: "promo",
          priority: TOAST_PRIORITIES.PROMO,
          title: (
            <Title>
              {promo.promoName}
              {promos?.started?.length && promos?.started?.length > 1 && (
                <LinkStyled
                  data-testid="promoChangeLink"
                  onClick={() => setIsModalOpen(true)}
                  linkSize="small"
                >
                  (Change Promo)
                </LinkStyled>
              )}
            </Title>
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoId, show, isFetched, isVisible]);

  return (
    <>
      <PromoPickerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        startedPromos={promos?.started}
      />
    </>
  );
};
