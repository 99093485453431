import { useCallback, useEffect, useRef } from "react";
import { EventTracker } from "@RooBeta/types";
import { useIntersectionObserver } from "usehooks-ts";

type SendEventParams = Parameters<typeof EventTracker.send>[0];

interface UseImpressionTrackingProps {
  event: SendEventParams;
  onEndOfResults?: () => void;
  delay?: number;
}

const eventCache = new Set<string>();

export const useImpressionTracking = ({
  event,
  onEndOfResults,
  delay = 1000,
}: UseImpressionTrackingProps) => {
  const timeout = useRef<number | null>(null);
  const hasTracked = useRef<boolean>(false);
  const { ref, isIntersecting } = useIntersectionObserver({
    threshold: 0.5,
  });

  const cacheKey = JSON.stringify({ event, delay });

  const logImpression = useCallback(() => {
    if (!hasTracked.current && !eventCache.has(cacheKey)) {
      EventTracker.send(event);
      hasTracked.current = true;
      eventCache.add(cacheKey);

      if (onEndOfResults) {
        onEndOfResults();
      }
    }
    timeout.current = null;
  }, [event, onEndOfResults, cacheKey]);

  useEffect(() => {
    if (isIntersecting && delay >= 0 && !hasTracked.current && !eventCache.has(cacheKey)) {
      timeout.current = window.setTimeout(logImpression, delay);
    } else if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    } else if (!isIntersecting && hasTracked.current) {
      eventCache.delete(cacheKey);
      hasTracked.current = false;
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      if (hasTracked.current) {
        eventCache.delete(cacheKey);
      }
    };
  }, [isIntersecting, logImpression, delay, cacheKey]);

  return ref;
};
