import { ShiftSearch, ShiftStatus } from "../ProfilePanels/ProfilePanelTypes";

import {
  CHANGE_SHIFT_STATUS,
  CLOSE_NEW_HOSPITAL_PROFILE_PANEL,
  RELOAD_SHIFTS_FOR_CONTRACTOR,
  RESET_RELOAD_SHIFTS_FOR_CONTRACTOR,
  SHOW_NEW_HOSPITAL_PROFILE_PANEL,
  UPDATE_ACTIVE_TAB,
} from "./constants";

export function showNewHospitalProfilePanel(data: any) {
  return {
    type: SHOW_NEW_HOSPITAL_PROFILE_PANEL,
    payload: data,
  };
}

export function closeNewHospitalProfilePanel() {
  return {
    type: CLOSE_NEW_HOSPITAL_PROFILE_PANEL,
  };
}

export function reloadShiftsForContractor(shiftSearch?: ShiftSearch) {
  return {
    type: RELOAD_SHIFTS_FOR_CONTRACTOR,
    payload: shiftSearch,
  };
}

export function resetReloadShiftsForContractor() {
  return {
    type: RESET_RELOAD_SHIFTS_FOR_CONTRACTOR,
  };
}

export function updateActiveTab(tab: number) {
  return {
    type: UPDATE_ACTIVE_TAB,
    payload: tab,
  };
}

export function changeShiftStatus(shiftStatus: ShiftStatus) {
  return {
    type: CHANGE_SHIFT_STATUS,
    payload: shiftStatus,
  };
}
