import { useQuery } from "@tanstack/react-query";

import { APIResponse } from "../../api/common/response";
import { get } from "../../api/common/rooFetch";
import { CACHE } from "../../api/utility/constants";
import { getWorkedHospitals as getWorkedHospitalsRoute } from "../../constants/apiRoutes";

export interface WorkedHospital {
  hospitalId: number;
  hospitalName: string;
  hospitalAddress: string;
  hospitalCity: string;
  hospitalProfileImagePath: string;
  totalRatings: number;
  lastDateWorked: string;
  stateCode: string;
  isFavorite: boolean;
}

export const getWorkedHospitals = async (minRating: number): Promise<WorkedHospital[]> => {
  const response = await get<APIResponse<WorkedHospital[]>>(getWorkedHospitalsRoute(minRating));

  return response.data;
};

export const useGetWorkedHospitals = (
  minRating: number,
  { onSuccess, enabled = true }: { onSuccess?: () => void; enabled?: boolean } = {}
) => {
  return useQuery([CACHE.WORKED_HOSPITALS, minRating], () => getWorkedHospitals(minRating), {
    onSuccess,
    enabled,
  });
};
