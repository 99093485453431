import { ButtonTabs, Heading } from "@RooUI";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect, useState } from "react";

import { CreateTechDataAccountResponse } from "../../../../api/tech";
import { CreateVetDataAccountResponse } from "../../../../api/vet";
import { CreateStudentDataAccountResponse } from "../../../../api/student";
import { useConferenceEvent } from "../../../hooks/useConferenceEvent";
import { HospitalSignUpContainer } from "../hospitalSignUp/HospitalSignUpContainer";
import { HeaderContainer } from "../styles";
import { StudentSignUpContainer } from "../studentSignUp/StudentSignUpContainer";
import { TechSignUpContainer } from "../techSignUp/TechSignUpContainer";
import {
  EventCalendlySignUp,
  EventCalendlySignUpProps,
  EventHospitalCalendlySignUp,
} from "../vetSignUp/EventCalendlySignUp";
import { VetSignUpContainer } from "../vetSignUp/VetSignUpContainer";

import { EventSignupSuccessContainer } from "./EventSignUpSuccessContainer";
import successImage from "./success.svg";
import { EventHospitalCalendlySignUpProps } from "../hospitalSignUp/types";

export type OnTechSignedUp = (data: CreateTechDataAccountResponse["data"]) => void;
export type OnHospitalHubSpotFormSubmitted = () => void;
export type OnVetSignedUp = (data: CreateVetDataAccountResponse["data"]) => void;

export type OnHospitalSignedUp = (data: EventHospitalCalendlySignUpProps) => void;
export type OnStudentSignedUp = (data: CreateStudentDataAccountResponse["data"]) => void;

export type CompletionStatus =
  | "approved"
  | "pending"
  | "hubSpotFormSubmitted"
  | "awaitingCalendly"
  | "studentRegistrationPending"
  | undefined;

export const EventSignUp = () => {
  const { eventSlug, booth, hospitalHubspotFormId } = useConferenceEvent();
  const [done, setDone] = useState<CompletionStatus>(undefined);
  const [calendly, setCalendly] = useState<EventCalendlySignUpProps | undefined>(undefined);
  const [hospitalCalendly, setHospitalCalendly] = useState<
    EventHospitalCalendlySignUpProps | undefined
  >(undefined);

  const postHog = usePostHog();

  const onTechSignedUp: OnTechSignedUp = (_data) => setDone("pending");
  const onHospitalHubSpotFormSubmitted: OnHospitalHubSpotFormSubmitted = () =>
    setDone("hubSpotFormSubmitted");
  const onStudentSignedUp: OnStudentSignedUp = (_data) => setDone("studentRegistrationPending");

  const onHospitalSignedUp: OnHospitalSignedUp = (props) => {
    setHospitalCalendly(props);
  };

  const onVetSignedUp: OnVetSignedUp = ({
    userId,
    vetId,
    email,
    phoneNumber,
    firstName,
    lastName,
  }) => {
    setCalendly({
      ...{
        userId: parseInt(userId),
        vetId: parseInt(vetId),
        email,
        phone: phoneNumber ?? "",
        name: `${firstName} ${lastName}`,
      },
      onBack: reset,
    });
    setDone("awaitingCalendly");
  };

  const reset = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setCalendly(undefined);
    setHospitalCalendly(undefined);
    setDone(undefined);
    postHog.reset();
  }, [postHog]);

  useEffect(() => {
    if (done) {
      const timeout = setTimeout(reset, 10000);
      return () => clearTimeout(timeout);
    }

    return () => null;
  }, [done, reset]);

  const getSuccessText = (done: CompletionStatus) => {
    switch (done) {
      case "approved":
        return "We have screened your license. Congratulations, you're approved! Start requesting shifts on Roo now!";
      case "hubSpotFormSubmitted":
        return "Thanks for submitting the form. We will get in touch with you shortly. Have a great conference.";
      case "awaitingCalendly":
        return "Thanks for signing up for Roo! Login on your own device to complete your registration.";
      case "studentRegistrationPending":
        return "Thanks for signing up for Roo! You will be able to finish registration via a link in your email.";
      default:
        return "You're signed up. You will be able to finish registration via a link in your email.";
    }
  };

  if (done) {
    return (
      <EventSignupSuccessContainer onClickButton={reset}>
        <img src={successImage} />
        <Heading level={3} data-testid="successText">
          {getSuccessText(done)}
        </Heading>
      </EventSignupSuccessContainer>
    );
  }

  if (calendly) {
    return <EventCalendlySignUp {...calendly} />;
  }

  if (hospitalCalendly) {
    return <EventHospitalCalendlySignUp {...hospitalCalendly} onBack={reset} />;
  }

  return (
    <>
      <HeaderContainer>
        <Heading level={1}>Welcome to Roo!</Heading>
        <Heading level={3}>Sign up as a</Heading>
      </HeaderContainer>
      <ButtonTabs
        useRouteNavigation
        routeBasePath={`/e/${eventSlug}`}
        tabNameArr={[
          {
            index: 0,
            name: "Vet",
            panelContent: (
              <VetSignUpContainer booth={booth} onSuccess={onVetSignedUp} eventSlug={eventSlug} />
            ),
            "data-testid": "vetTab",
            path: "vet",
          },
          {
            index: 1,
            name: "Vet Tech / Assistant",
            panelContent: (
              <TechSignUpContainer booth={booth} onSuccess={onTechSignedUp} eventSlug={eventSlug} />
            ),
            "data-testid": "techTab",
            path: "tech",
          },
          {
            index: 2,
            name: "Hospital Manager / Owner",
            panelContent: (
              <HospitalSignUpContainer
                eventSlug={eventSlug}
                onHubspotSuccess={onHospitalHubSpotFormSubmitted}
                onSuccess={onHospitalSignedUp}
                hospitalHubspotFormId={hospitalHubspotFormId}
                booth={booth}
              />
            ),
            "data-testid": "hospitalTab",
            path: "hospital",
          },
          {
            index: 3,
            name: "DVM Student",
            panelContent: (
              <StudentSignUpContainer
                booth={booth}
                onSuccess={onStudentSignedUp}
                eventSlug={eventSlug}
              />
            ),
            "data-testid": "studentTab",
            path: "student",
          },
        ]}
        defaultSelectedTabIndex={0}
      />
    </>
  );
};
