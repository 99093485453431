import { useDispatch, useSelector } from "react-redux";

import { PromoTracking } from "../../api/promos";
import { VET_USER_TYPE } from "../../constants/UserTypeConstants";
import {
  getTechPromoShifts,
  toggleTechPromoShiftPanel,
} from "../../vetTechDashboard/actions/techDashboardActions";
import {
  getVetPromoShifts,
  toggleVetPromoShiftPanel,
} from "../../vetTechDashboard/actions/vetDashboardActions";

export const useHandlePromoPanelOpen = ({ promoId, promoName }: Partial<PromoTracking>) => {
  const dispatch = useDispatch();
  const { vetId, techId, userTypeId } = useSelector((state: any) => state.login);
  const isVet = parseInt(userTypeId) === VET_USER_TYPE;

  const handlePromoPanelOpen = () => {
    if (isVet) {
      dispatch(getVetPromoShifts({ vetId, promoId }));
      dispatch(toggleVetPromoShiftPanel({ promoName }));
    } else {
      dispatch(getTechPromoShifts({ techId, promoId }));
      dispatch(toggleTechPromoShiftPanel({ promoName }));
    }
  };
  return { handlePromoPanelOpen };
};
