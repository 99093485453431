import { InputProps, InputRef } from "antd";
import React from "react";

import { grayLight400 } from "../../styles/constants";
import { FormLabel } from "../FormLabel";
import { Icon } from "../Icon";
import { ErrorText } from "../Text/ErrorText";

import { CustomInput, InputContainer } from "./AntInputStyles";

interface ExtendedInputProps extends InputProps {
  error?: string;
  isHideErrorText?: boolean;
  label?: string;
  disabled?: boolean;
  handleClearSearch?: (e?: any) => void;
}

export type AntInputProps = ExtendedInputProps & React.RefAttributes<InputRef>;

export type { InputRef };

export const Input: React.ForwardRefExoticComponent<AntInputProps> = React.forwardRef(
  function InputComponent(props, ref) {
    const {
      isHideErrorText,
      name,
      id,
      error,
      label,
      disabled,
      allowClear,
      handleClearSearch,
      "data-testid": dataTestId,
    } = props;

    const htmlFor = id ?? `input-${name}`;

    const customInputStyledProps = {
      $status: error ? "error" : "",
      $suffix: props.suffix,
      $allowClear: allowClear,
    };

    return (
      <InputContainer>
        <FormLabel htmlFor={htmlFor} $disabled={disabled} error={!!error}>
          {label}
        </FormLabel>
        <CustomInput
          {...props}
          {...customInputStyledProps}
          ref={ref}
          id={htmlFor}
          onWheel={(e) => e.currentTarget.blur()}
          status={error ? "error" : ""}
          allowClear={
            allowClear && {
              clearIcon: (
                <Icon
                  name="Close"
                  color={grayLight400}
                  onClick={(e) => handleClearSearch && handleClearSearch(e)}
                />
              ),
            }
          }
        />
        {error && !isHideErrorText && (
          <ErrorText data-testid={`${dataTestId}Error`}>{error}</ErrorText>
        )}
      </InputContainer>
    );
  }
);
