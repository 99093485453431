export type SpacingSize =
  | "7x"
  | "6x"
  | "5x"
  | "4x"
  | "3x"
  | "xxl"
  | "xl"
  | "l"
  | "m"
  | "s"
  | "xs"
  | "xxs";

export type SpacingKey = `spacing${Uppercase<SpacingSize>}`;

export const spacing: Record<SpacingSize, string> = {
  "7x": "96px",
  "6x": "72px",
  "5x": "64px",
  "4x": "44px",
  "3x": "32px",
  xxl: "24px",
  xl: "20px",
  l: "16px",
  m: "12px",
  s: "8px",
  xs: "4px",
  xxs: "2px",
} as const;

// For backward compatibility with existing imports
export const spacing7X = spacing["7x"];
export const spacing6X = spacing["6x"];
export const spacing5X = spacing["5x"];
export const spacing4X = spacing["4x"];
export const spacing3X = spacing["3x"];
export const spacingXXL = spacing.xxl;
export const spacingXL = spacing.xl;
export const spacingL = spacing.l;
export const spacingM = spacing.m;
export const spacingS = spacing.s;
export const spacingXS = spacing.xs;
export const spacingXXS = spacing.xxs;
