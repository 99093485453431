import { Divider, MediumText } from "@RooUI";
import { Drawer } from "antd";
import React, { useCallback } from "react";

import { clearAuthAndRedirect } from "@Roo/LogOut/LogOut";
import { useRequiredAuthorizedUser, useRoutes, useTracking } from "@RooBeta/hooks";

import { ProviderRestricted, RooIcon } from "..";
import { DrawerNavigation } from "./DrawerNavigation";
import { NAVIGATION_ITEMS } from "./navigationItems";
import { useActiveRoute } from "./useActiveRoute";
import { getTrackingOptions, handleBackToAdmin, handleFeedback } from "./util";

interface ContractorNavigationDrawerProps {
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  isOpen: boolean;
}

export const ContractorNavigationDrawer = ({
  setIsOpen,
  children,
  isOpen,
}: ContractorNavigationDrawerProps) => {
  const {
    provider: { providerType },
    isSuperUser,
  } = useRequiredAuthorizedUser();

  const withTracking = useTracking({
    providerType,
  });

  const routes = useRoutes();
  const { isActive } = useActiveRoute({ baseRoute: routes.SHIFTS });

  const handleNavigationItemClick = useCallback(
    (path: string) => {
      setIsOpen(false);
      withTracking(() => {}, getTrackingOptions(path));
    },
    [withTracking, setIsOpen]
  );

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <Drawer
        placement="right"
        onClose={() => setIsOpen(false)}
        open={isOpen}
        height="auto"
        closeIcon={<RooIcon icon="clear" size="l" />}
        styles={{
          body: {
            padding: 0,
          },
          header: {
            alignSelf: "flex-end",
            borderBottom: "none",
            paddingBottom: "0",
          },
        }}
      >
        <DrawerNavigation.Main label="Account navigation">
          <DrawerNavigation.Item
            to=""
            icon={NAVIGATION_ITEMS.DESIGN.RATE.icon}
            onClick={handleFeedback}
          >
            <MediumText>{NAVIGATION_ITEMS.DESIGN.RATE.label}</MediumText>
          </DrawerNavigation.Item>
          <Divider />
          <DrawerNavigation.Item
            active={isActive(NAVIGATION_ITEMS.MAIN.SCHEDULE.path(providerType))}
            icon={NAVIGATION_ITEMS.MAIN.SCHEDULE.icon}
            to={NAVIGATION_ITEMS.MAIN.SCHEDULE.path(providerType)}
            onClick={() => handleNavigationItemClick("Schedule")}
          >
            <MediumText>{NAVIGATION_ITEMS.MAIN.SCHEDULE.label}</MediumText>
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            active={isActive(NAVIGATION_ITEMS.MAIN.SHIFTS.path(providerType))}
            icon={NAVIGATION_ITEMS.MAIN.SHIFTS.icon}
            to={NAVIGATION_ITEMS.MAIN.SHIFTS.path(providerType)}
            onClick={() => handleNavigationItemClick("Shifts")}
          >
            <MediumText>{NAVIGATION_ITEMS.MAIN.SHIFTS.label}</MediumText>
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            active={isActive(NAVIGATION_ITEMS.MAIN.EARNINGS.path(providerType))}
            icon={NAVIGATION_ITEMS.MAIN.EARNINGS.icon}
            to={NAVIGATION_ITEMS.MAIN.EARNINGS.path(providerType)}
            onClick={() => handleNavigationItemClick("Earnings")}
          >
            <MediumText>{NAVIGATION_ITEMS.MAIN.EARNINGS.label}</MediumText>
          </DrawerNavigation.Item>
          <DrawerNavigation.Group label="Account">
            <DrawerNavigation.Item
              active={isActive(NAVIGATION_ITEMS.ACCOUNT.PROFILE.path(providerType))}
              icon={NAVIGATION_ITEMS.ACCOUNT.PROFILE.icon}
              to={NAVIGATION_ITEMS.ACCOUNT.PROFILE.path(providerType)}
              onClick={() => handleNavigationItemClick("Profile")}
            >
              <MediumText>{NAVIGATION_ITEMS.ACCOUNT.PROFILE.label}</MediumText>
            </DrawerNavigation.Item>
            <ProviderRestricted providerType="VET">
              <DrawerNavigation.Item
                active={isActive(NAVIGATION_ITEMS.ACCOUNT.PROMOS.path(providerType))}
                icon={NAVIGATION_ITEMS.ACCOUNT.PROMOS.icon}
                to={NAVIGATION_ITEMS.ACCOUNT.PROMOS.path(providerType)}
                onClick={() => handleNavigationItemClick("Promos")}
              >
                <MediumText>{NAVIGATION_ITEMS.ACCOUNT.PROMOS.label}</MediumText>
              </DrawerNavigation.Item>
            </ProviderRestricted>
            <DrawerNavigation.Item
              active={isActive(NAVIGATION_ITEMS.ACCOUNT.REFER.path(providerType))}
              icon={NAVIGATION_ITEMS.ACCOUNT.REFER.icon}
              to={NAVIGATION_ITEMS.ACCOUNT.REFER.path(providerType)}
              onClick={() => handleNavigationItemClick("Refer & Earn")}
            >
              <MediumText>{NAVIGATION_ITEMS.ACCOUNT.REFER.label}</MediumText>
            </DrawerNavigation.Item>
          </DrawerNavigation.Group>
          <Divider />
          <DrawerNavigation.Item
            active={isActive(NAVIGATION_ITEMS.RESOURCES.POLICIES.path(providerType))}
            icon={NAVIGATION_ITEMS.RESOURCES.POLICIES.icon}
            to={NAVIGATION_ITEMS.RESOURCES.POLICIES.path(providerType)}
            onClick={() => handleNavigationItemClick("Policies")}
          >
            <MediumText>{NAVIGATION_ITEMS.RESOURCES.POLICIES.label}</MediumText>
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            active={isActive(NAVIGATION_ITEMS.RESOURCES.TOOLKIT.path)}
            icon={NAVIGATION_ITEMS.RESOURCES.TOOLKIT.icon}
            to={NAVIGATION_ITEMS.RESOURCES.TOOLKIT.path}
            onClick={() => handleNavigationItemClick("Relief Resource Toolkit")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MediumText>{NAVIGATION_ITEMS.RESOURCES.TOOLKIT.label}</MediumText>
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            active={isActive(NAVIGATION_ITEMS.SETTINGS.path)}
            icon={NAVIGATION_ITEMS.SETTINGS.icon}
            to={NAVIGATION_ITEMS.SETTINGS.path}
            onClick={() => handleNavigationItemClick("Settings")}
          >
            <MediumText>{NAVIGATION_ITEMS.SETTINGS.label}</MediumText>
          </DrawerNavigation.Item>
          <DrawerNavigation.Item
            icon={NAVIGATION_ITEMS.LOGOUT.icon}
            onClick={clearAuthAndRedirect}
            to=""
          >
            <MediumText>{NAVIGATION_ITEMS.LOGOUT.label}</MediumText>
          </DrawerNavigation.Item>

          {isSuperUser && (
            <DrawerNavigation.Item
              icon={NAVIGATION_ITEMS.ADMIN.BACK.icon}
              onClick={handleBackToAdmin}
              to=""
            >
              <MediumText>{NAVIGATION_ITEMS.ADMIN.BACK.label}</MediumText>
            </DrawerNavigation.Item>
          )}
        </DrawerNavigation.Main>
      </Drawer>
    </>
  );
};
