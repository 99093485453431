import React, { useContext } from "react";

import { useUpdateVet } from "../../../hooks/api";
import { Event, EventTracker } from "../../../tracking/service/EventTracker/EventTrackerService";
import { RELIEF_WORK_OPTIONS } from "../../../constants/radioConstants";
import { QuestionInterstitial, QuestionType } from "./QuestionInterstitial";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { ToastContext } from "@Roo/Common/Toasts/context/ToastContext";
import { APIError } from "../../../api/common/rooFetch";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";

export const ReliefWorkInterstitial = () => {
  const [selectedValue, setSelectedValue] = React.useState<number | null>(null);
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  const { vetId } = useRequiredAuthorizedUser();

  const ref = useImpressionTracking({
    event: {
      eventName: Event.Name.VET_VIEW_CURRENT_SITUATION,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET,
      entityId: vetId ?? undefined,
    },
  });

  const { mutate: updateVet } = useUpdateVet({
    onSuccess: () => {
      showSuccessToast({ description: "Profile updated successfully" });
    },
    onError: (error: APIError<{ message: string }>) => {
      showErrorToast({ description: error?.data?.message ?? "Error updating profile" });
    },
  });

  const handleSubmit = (value: number) => {
    updateVet({ currentSituationId: value });
    EventTracker.send({
      eventName: Event.Name.VET_SUBMIT_CURRENT_SITUATION,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.VET,
      entityId: vetId ?? undefined,
    });
  };

  return (
    <div ref={ref}>
      <QuestionInterstitial
        questionType={QuestionType.RADIO}
        options={RELIEF_WORK_OPTIONS}
        label="What is your relief work status?"
        value={selectedValue}
        onChange={setSelectedValue}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
