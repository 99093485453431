import { blueDark600, grayBase, purpleBase, purpleDark600, tealDark600 } from "./colors";
import { primaryFont, secondaryFont } from "./fonts";

export const h1Headlines = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 52px;
color: ${purpleDark600};
margin-bottom: 0;
@media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
}
`;

export const h2Headlines = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 44px;
color: ${grayBase};
margin-bottom: 0;
@media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
}
`;

export const h3Headlines = `
font-family: ${secondaryFont};
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: ${grayBase};
margin-bottom: 0;
@media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
}
`;

export const h4Headlines = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
letter-spacing: 2px;
text-transform: uppercase;
color: ${purpleBase};
margin-bottom: 0;
`;

export const h5Headlines = `
font-family: ${secondaryFont};
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 28px;
color: ${grayBase};
margin-bottom: 0;
`;

export const h6Headlines = `
font-family: ${secondaryFont};
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 16px;
color: ${tealDark600};
margin-bottom: 0;
`;

export const FormLabelText = `
font-family: ${secondaryFont};
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: ${blueDark600};
margin-bottom: 2px;
`;

export const BodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: ${grayBase};
`;

export const MediumBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: ${grayBase};
`;

export const BoldBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: ${grayBase};
`;

export const SmallBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
color: ${grayBase};
`;

export const SmallMediumBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
color: ${grayBase};
`;

export const SmallBoldBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 20px;
color: ${grayBase};
`;
export const ButtonText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: ${grayBase};
`;

export const LargeBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 26px;
color: ${grayBase};
`;

export const LargeMediumBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 26px;
color: ${grayBase};
`;

export const LargeBoldBodyText = `
font-family: ${primaryFont};
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
color: ${grayBase};
`;
