import { CSSProperties } from "react";
import styled from "styled-components";

import {
  SmallBodyText,
  containerBorderRadius,
  grayBackground,
  grayLight50,
  grayWhite,
  purpleBackground,
  smallMediaBreakpoint,
  spacingL,
  spacingM,
  spacingS,
  spacingXS,
  spacingXXL,
} from "../../styles/constants";

export const SectionCardContainer = styled.div<{ $overrideStyles?: CSSProperties }>`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  background-color: ${grayWhite};
  border-radius: ${containerBorderRadius};
  border: 2px solid ${grayLight50};

  ${(props) => props.$overrideStyles && { ...props.$overrideStyles }}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: ${spacingXXL} 32px;
  background-color: ${purpleBackground};
  border-bottom: 2px solid ${grayBackground};
  height: 80px;

  @media (max-width: ${smallMediaBreakpoint}) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: ${spacingL} 32px;
  }
`;

export const HeadingTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingL};
`;

export const TaskProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${spacingS};
  width: 240px;
  height: 20px;

  @media (max-width: ${smallMediaBreakpoint}) {
    margin-top: ${spacingS};
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100px;

  .ant-progress {
    margin: 0;
  }
`;

export const TaskProgressCount = styled.div`
  ${SmallBodyText}
  margin: 0 !important;

  p {
    display: inline;
    font-size: 12px;
  }
`;

export const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const CelebrationEmoji = styled.div`
  font-size: 44px;
  line-height: 52px;
`;

export const CompletionMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-grow: 1;

  > *:first-child {
    margin-bottom: ${spacingM};
    font-weight: 500;
  }

  > *:nth-child(2) {
    margin-top: ${spacingXS};
    margin-bottom: ${spacingXS};
  }
`;
