import { SliderSingleProps } from "antd/es/slider";
import React, { useEffect } from "react";
import { formatPrice } from "../../../../helpers/textUtility";
import { transformPositionToValue, transformValueToPosition } from "./PricingSliderService";
import {
  PriceLabel,
  PriceLabelContainer,
  PriceSubLabel,
  StyledPricingSlider,
} from "./PricingSliderStyles";

interface PricingSliderProps extends Omit<SliderSingleProps, "value" | "onChange" | "min" | "max"> {
  variant?: "default" | "recommended";
  minPrice: number;
  maxPrice: number;
  recommendedPrice?: number; // used for recommended variant, must be between min and max
  value: number;
  enforceIntegerPricing?: boolean; // floors the value to the nearest integer
  onChange: (value: number) => void;
  "data-testid"?: string;
}

export const PricingSlider = ({
  variant = "default",
  minPrice,
  maxPrice,
  recommendedPrice,
  value,
  enforceIntegerPricing = false,
  onChange,
  "data-testid": dataTestId,
  ...props
}: PricingSliderProps) => {
  const isRecommendedVariant = variant === "recommended" && recommendedPrice !== undefined;

  const handleChange = (newPosition: number) => {
    let newValue: number;
    if (isRecommendedVariant) {
      newValue = transformPositionToValue({
        position: newPosition,
        minPrice,
        maxPrice,
        isRecommendedVariant: true,
        recommendedPrice,
      });
    } else {
      newValue = transformPositionToValue({
        position: newPosition,
        minPrice,
        maxPrice,
        isRecommendedVariant: false,
      });
    }
    onChange(newValue);
  };

  const getSliderPosition = () => {
    if (isRecommendedVariant) {
      return transformValueToPosition({
        value: Number(value),
        minPrice,
        maxPrice,
        isRecommendedVariant: true,
        recommendedPrice,
      });
    } else {
      return transformValueToPosition({
        value: Number(value),
        minPrice,
        maxPrice,
        isRecommendedVariant: false,
      });
    }
  };

  const sliderPosition = getSliderPosition();

  useEffect(() => {
    if (enforceIntegerPricing && value !== Math.floor(value)) {
      onChange(Math.floor(value));
    }
  }, [value, enforceIntegerPricing, onChange]);

  return (
    <div data-testid={dataTestId}>
      <StyledPricingSlider
        {...props}
        $isRecommendedVariant={isRecommendedVariant}
        min={0}
        max={100}
        value={sliderPosition}
        onChange={handleChange}
        marks={{
          0: minPrice,
          50: isRecommendedVariant ? recommendedPrice : undefined,
          100: maxPrice,
        }}
        tooltip={{ open: false }}
      />
      <PriceLabelContainer>
        <div>
          <PriceLabel data-testid="minPriceLabel">{formatPrice(minPrice)}</PriceLabel>
          {isRecommendedVariant && <PriceSubLabel>Minimum</PriceSubLabel>}
        </div>
        {isRecommendedVariant && (
          <div>
            <PriceLabel data-testid="recommendedPriceLabel">
              {formatPrice(recommendedPrice)}
            </PriceLabel>
            <PriceSubLabel>Recommended</PriceSubLabel>
          </div>
        )}
        <div>
          <PriceLabel data-testid="maxPriceLabel">{formatPrice(maxPrice)}</PriceLabel>
          {isRecommendedVariant && <PriceSubLabel>Maximum</PriceSubLabel>}
        </div>
      </PriceLabelContainer>
    </div>
  );
};
