import React, { createContext, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { CalendlyStatus, GetUserInfoResponse, useGetUserInfo } from "../api/user/useGetUserInfo";
import { AccountActivationCallModal } from "../Common/Modals/AccountActivationCallModal";
import { CalendlyPointOfEntry } from "../constants/posthogEventContextTypes";
import { TECH_USER_TYPE, VET_USER_TYPE } from "../constants/UserTypeConstants";

interface VetTechDashboardContextType {
  showOnboardingBanner: boolean;
  calendlyCallStartDatetime: string | null;
  calendlyStatus: CalendlyStatus | null;
  userInfo?: GetUserInfoResponse["data"]["results"][number];
  userInfoFetched: boolean;

  // Used to know how the user got to account activation modal (which leads to Calendly)
  setPointOfEntry: (pointOfEntry: CalendlyPointOfEntry | null) => void;
}

const defaultContextValue: VetTechDashboardContextType = {
  showOnboardingBanner: false,
  calendlyCallStartDatetime: null,
  userInfoFetched: false,
  calendlyStatus: null,

  setPointOfEntry: () => {},
};
const VetTechDashboardContext = createContext<VetTechDashboardContextType>(defaultContextValue);

export const VetTechDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [pointOfEntry, setPointOfEntry] = useState<CalendlyPointOfEntry | null>(null);

  const loginData: any = useSelector((state: any) => state.login);
  const { email, vetId, userTypeId, isShowOnboarding } = loginData;

  const isVet = parseInt(userTypeId) === VET_USER_TYPE;
  const isTech = parseInt(userTypeId) === TECH_USER_TYPE;

  const { data: getUserInfoData, isFetched: userInfoFetched } = useGetUserInfo({
    userTypeId: isVet ? VET_USER_TYPE : TECH_USER_TYPE,
    email,
    enabled: !!vetId,
  });

  const userInfo = getUserInfoData?.data?.results[0];

  const contextValue = useMemo(() => {
    const showTechOnboardingBanner = isTech && isShowOnboarding === 0;
    const showVetOnboardingBanner = isVet && isShowOnboarding === 0 && userInfoFetched;

    return {
      showOnboardingBanner: showTechOnboardingBanner || showVetOnboardingBanner,
      calendlyCallStartDatetime: userInfo?.calendlyCallStartDatetime || null,
      calendlyStatus: userInfo?.calendlyStatus || null,
      pointOfEntry,
      setPointOfEntry,
      userInfo,
      userInfoFetched,
    };
  }, [userInfoFetched, userInfo, isVet, isTech, isShowOnboarding, pointOfEntry]);

  return (
    <VetTechDashboardContext.Provider value={contextValue}>
      {!!pointOfEntry && (
        <AccountActivationCallModal
          vetId={vetId}
          showModal={!!pointOfEntry}
          pointOfEntry={pointOfEntry}
          onCloseModal={() => setPointOfEntry(null)}
        />
      )}

      {children}
    </VetTechDashboardContext.Provider>
  );
};

export const useVetTechDashboardContext = () => {
  const context = useContext(VetTechDashboardContext);
  if (context === undefined) {
    throw new Error("useVetTechDashboardContext must be used within a VetTechDashboardProvider");
  }
  return context;
};

export { VetTechDashboardContext };
