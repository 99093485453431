interface PriceRange {
  minPrice: number;
  maxPrice: number;
}

interface DefaultVariant extends PriceRange {
  isRecommendedVariant: false;
  recommendedPrice?: never;
}

interface RecommendedVariant extends PriceRange {
  isRecommendedVariant: true;
  recommendedPrice: number;
}

type SliderParams = DefaultVariant | RecommendedVariant;

type PositionToValueParams = SliderParams & {
  position: number;
};

type ValueToPositionParams = SliderParams & {
  value: number;
};

export const transformPositionToValue = ({
  position,
  minPrice,
  maxPrice,
  isRecommendedVariant,
  recommendedPrice,
}: PositionToValueParams): number => {
  let value: number;
  if (!isRecommendedVariant) {
    // linear transformation for default variant
    value = minPrice + (position / 100) * (maxPrice - minPrice);
  } else if (position <= 50) {
    // left half
    value = minPrice + (position / 50) * (recommendedPrice - minPrice);
  } else {
    // right half
    value = recommendedPrice + ((position - 50) / 50) * (maxPrice - recommendedPrice);
  }
  return Number(value.toFixed(2));
};

export const transformValueToPosition = ({
  value,
  minPrice,
  maxPrice,
  isRecommendedVariant,
  recommendedPrice,
}: ValueToPositionParams): number => {
  if (!isRecommendedVariant) {
    // linear transformation for default variant
    return ((value - minPrice) / (maxPrice - minPrice)) * 100;
  }

  if (value <= recommendedPrice) {
    // left half
    return ((value - minPrice) / (recommendedPrice - minPrice)) * 50;
  } else {
    // right half
    return 50 + ((value - recommendedPrice) / (maxPrice - recommendedPrice)) * 50;
  }
};
