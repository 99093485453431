import { useGetPendingSetupIntents } from "./useGetPendingSetupIntents";

interface UseStripeVerificationStatusProps {
  userType: "vet" | "tech";
}

interface UseStripeVerificationStatusResult {
  isVerificationReady: boolean;
  hostedVerificationUrl: string | null;
  isLoading: boolean;
  hasPendingSetupIntent: boolean;
}

export const useStripeVerificationStatus = ({
  userType,
}: UseStripeVerificationStatusProps): UseStripeVerificationStatusResult => {
  const { data, isLoading } = useGetPendingSetupIntents({ userType });

  if (!data || data.length === 0 || isLoading) {
    return {
      isVerificationReady: false,
      hostedVerificationUrl: null,
      isLoading,
      hasPendingSetupIntent: false,
    };
  }

  const { arrival_date, hosted_verification_url } = data[0].next_action.verify_with_microdeposits;
  const isVerificationReady = Date.now() / 1000 > arrival_date;

  return {
    isVerificationReady,
    hostedVerificationUrl: hosted_verification_url,
    isLoading,
    hasPendingSetupIntent: true,
  };
};
