import React from "react";
import pawPrintCircle from "@Roo/static/images/paw-print-circle.svg";
import { BodyText, grayBase, grayLight400, grayLightest, Heading, Container } from "@RooUI";
import styled from "styled-components";
import { Image } from "antd";
import { VStack } from "../Flex";

export const NoShifts = () => {
  return (
    <Container
      data-testid="noShiftsContainer"
      backgroundColor={grayLightest}
      borderColor={grayLightest}
      style={{
        width: "100%",
      }}
    >
      <VStack $alignItems="center" $gap="m">
        <Image
          src={pawPrintCircle}
          width={48}
          height={48}
          preview={false}
          alt="paw print"
          data-testid="noShiftsPawPrint"
        />
        <TextContainer data-testid="noShiftsTextContainer">
          <Heading level={5} color={grayBase} data-testid="noShiftsHeading">
            Ruh-roh! Our pouch is empty
          </Heading>
          <Body data-testid="noShiftsMessage">Try changing dates or clearing filters</Body>
        </TextContainer>
      </VStack>
    </Container>
  );
};

const Body = styled.div`
  ${BodyText}
  color: ${grayLight400};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
