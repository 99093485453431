import { useMutation } from "@tanstack/react-query";

import { post, put, queryClient } from "@RooBeta/api";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import {
  CacheKey,
  CancelConfirmedShift,
  CancelRequestedShift,
  Provider,
  RequestCancelConfirmedShift,
  ShiftCancellation,
  ShiftDetails,
} from "@RooBeta/types";
import { isWithinBusinessDays } from "@RooBeta/utils";
import { useToastStore } from "@RooBeta/store";
import { TOAST_DURATION, TOAST_PRIORITIES } from "@RooBeta/store/useToastStore";

const cancelRequestedShift = (provider: Provider, data: CancelRequestedShift) =>
  put(
    provider.providerType === "VET"
      ? `api/hospital/shift/deleteShiftRequest`
      : `api/hospital/tech_shift/deleteShiftRequest`,
    { ...data }
  );

const cancelConfirmedShift = (provider: Provider, data: CancelConfirmedShift) =>
  post(
    provider.providerType === "VET" ? "api/vet/shift/removeVet" : "api/tech/shift/removeTech",
    data
  );

const requestCancelConfirmedShift = (provider: Provider, data: RequestCancelConfirmedShift) =>
  post(
    provider.providerType === "VET"
      ? `api/hospital/shift/requestShiftCancellation`
      : `api/hospital/tech_shift/requestShiftCancellation`,
    data
  );

type ShiftCancellationResult =
  | "request_cancelled"
  | "shift_cancellation_requested"
  | "shift_cancelled";

export const useShiftCancelMutation = ({ shiftDetails }: { shiftDetails?: ShiftDetails }) => {
  const { userId, emailId, firstName, lastName, provider } = useRequiredAuthorizedUser();
  const { show } = useToastStore();
  return useMutation({
    mutationFn: async ({
      reasonCode,
      comment,
    }: ShiftCancellation): Promise<ShiftCancellationResult> => {
      if (!shiftDetails) {
        throw new Error();
      }

      const { shiftId, shiftStatus } = shiftDetails;

      if (shiftStatus === "REQUESTED") {
        await cancelRequestedShift(provider, {
          userId,
          shiftId,
          shiftGroupId: shiftDetails.shiftGroupId ?? 0,
          vets: provider.providerType === "VET" ? [provider.providerId] : undefined,
          techs: provider.providerType === "TECH" ? [provider.providerId] : undefined,
          removeRequestReasons: reasonCode,
          requestAdditionalComment: comment,
        });

        show({
          variant: "success",
          title: "Request Cancelled",
          message: "Your shift request has been successfully cancelled.",
          region: "panel",
          priority: TOAST_PRIORITIES.SERVER_RESPONSE,
          duration: TOAST_DURATION,
        });

        return "request_cancelled";
      } else if (isWithinBusinessDays(shiftDetails.shiftDate, 4)) {
        await requestCancelConfirmedShift(provider, {
          contractorRemovalReason: comment ?? "",
          additionalComments: comment ?? "",
          userName: `${firstName} ${lastName}`,
          email: emailId,
          contractorId: provider.providerId,
          shiftDate: shiftDetails.shiftDate ?? "",
          hospitalName: shiftDetails.hospitalName ?? "",
          hospitalId: shiftDetails.hospitalId ?? 0,
          shiftGroupId: shiftDetails.shiftGroupId ?? 0,
          isVet: provider.providerType === "VET",
          isTestTicket: process.env.ENV !== "production",
        });
        show({
          variant: "success",
          title: "Cancellation Request Submitted",
          message: "Your cancellation request has been submitted for review.",
          region: "panel",
          priority: TOAST_PRIORITIES.SERVER_RESPONSE,
          duration: TOAST_DURATION,
        });
        return "shift_cancellation_requested";
      } else {
        const data: CancelConfirmedShift =
          provider.providerType === "VET"
            ? {
                vetId: provider.providerType === "VET" ? provider.providerId : undefined,
                shiftId,
                shiftGroupId: shiftDetails.shiftGroupId ?? 0,
                hospitalId: shiftDetails.hospitalId ?? 0,
                removedByUserId: userId,
                userInitiated: `${reasonCode}`,
                additionalComments: comment ?? null,
                contractorRemovalReason: comment ?? "",
                removalReasonId: reasonCode,
                prevContractorConfirmed: 1,
                replacement: 0,
                rttrNeeded: 0,
                shiftNotNedded: 0,
                contractorCallout: "1",
                shiftActivity: "1",
                creditRefund: "0",
                isSendDataToHubspot: true,
                postSlackMsg: true,
              }
            : {
                techId: provider.providerType === "TECH" ? provider.providerId : undefined,
                shiftId,
                shiftGroupId: shiftDetails.shiftGroupId ?? 0,
                hospitalId: shiftDetails.hospitalId ?? 0,
                additionalComments: comment ?? null,
                contractorRemovalReason: comment ?? "",
                removalReasonId: reasonCode,
              };

        await cancelConfirmedShift(provider, data);

        show({
          variant: "success",
          title: "Shift Cancelled",
          message: "Your shift has been successfully cancelled.",
          region: "panel",
          priority: TOAST_PRIORITIES.SERVER_RESPONSE,
          duration: TOAST_DURATION,
        });

        return "shift_cancelled";
      }
    },
    onSuccess: () => {
      const { shiftGroupId, hospitalId } = shiftDetails || {};
      queryClient.invalidateQueries({ queryKey: [CacheKey.Search] });
      queryClient.invalidateQueries({ queryKey: [CacheKey.ShiftDetails, { shiftGroupId }] });
      queryClient.invalidateQueries({ queryKey: [CacheKey.HospitalShifts, { hospitalId }] });
    },
  });
};
