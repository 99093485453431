import { Alert, SmallText, spacingXL, spacingXXL } from "@RooUI";
import { Skeleton } from "antd";
import React, { useMemo } from "react";

import { ShiftCard } from "@RooBeta/components";
import { useHospitalShifts, useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";
import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";

import { TabContentContainer } from "./styles";
import { HospitalShiftProposal } from "@RooBeta/components/shiftCard/HospitalShiftProposal";

export const HospitalShifts = () => {
  const { provider } = useRequiredAuthorizedUser();
  const { hospitalId } = useShiftDetailsStore();
  const { data: hospitalShifts, isLoading, error } = useHospitalShifts({ hospitalId });
  const count = useMemo(() => hospitalShifts?.length ?? 0, [hospitalShifts]);

  return hospitalShifts ? (
    <>
      {hospitalShifts.map((shift, index) => (
        <ShiftCard
          key={shift.shiftId}
          shift={{ ...shift, index }}
          count={count}
          variant="drawer"
          pointOfEntry={ShiftCardPointOfEntry.HospitalShiftsPane}
          provider={provider}
        />
      ))}
      {hospitalShifts.length === 0 && <WrappedHospitalShiftProposal hospitalId={hospitalId} />}
    </>
  ) : isLoading ? (
    <TabContentContainer>
      <Skeleton active />
    </TabContentContainer>
  ) : error ? (
    <TabContentContainer>
      <Alert $alertStatus="error" alertBody={<SmallText>Oops, something went wrong.</SmallText>} />
    </TabContentContainer>
  ) : null;
};

const WrappedHospitalShiftProposal = ({ hospitalId }: { hospitalId?: number }) => {
  if (!hospitalId) return null;
  return (
    <div style={{ padding: `${spacingXXL} ${spacingXL} ${spacingXL} ${spacingXL}` }}>
      <HospitalShiftProposal hospitalId={hospitalId} />
    </div>
  );
};
