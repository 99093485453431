export enum CalendlyPointOfEntry {
  ActivationCallBanner = "ActivationCallBanner",
  ShiftRequestSidebar = "ShiftRequestSidebar",
  RegistrationMeetYourRooRep = "RegistrationMeetYourRooRep",
  // Fallback case
  Unknown = "Unknown",
}

export enum ShiftCardPointOfEntry {
  MyShiftsPane = "MyShiftsPane",
  MapCarousel = "MapCarousel",
  SearchShiftsPane = "SearchShiftsPane",
  HospitalShiftsPane = "HospitalShiftsPane",
}
