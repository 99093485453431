import { Skeleton } from "antd";
import React from "react";

import { VStack } from "@RooBeta/components";
import { useShiftDetailsStore } from "@RooBeta/store";

import { HospitalCTA } from "./CTA/HospitalCTA";
import { ShiftCTA } from "./CTA/ShiftCTA";
import { Toast } from "@RooBeta/components/Toast";
import { FlexLayout } from "@RooUI";
import { FooterContainer } from "./styles";

export const ShiftDetailsFooter = () => {
  const { shiftDetails, tab, loading } = useShiftDetailsStore();

  return (
    <FlexLayout vertical>
      <Toast activeRegion="panel" />
      <FooterContainer data-testid="sidePanelFooter">
        {!shiftDetails || loading.shiftDetails ? (
          <VStack $alignItems="center" $padding="m">
            <Skeleton.Button active block />
          </VStack>
        ) : (
          <>
            {tab === "info" && <ShiftCTA />}
            {tab === "hospital" && <HospitalCTA />}
          </>
        )}
      </FooterContainer>
    </FlexLayout>
  );
};
