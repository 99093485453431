import React from "react";
import pawPrintCircle from "@Roo/static/images/paw-print-circle.svg";
import {
  BodyText,
  grayBase,
  grayLight400,
  grayLightest,
  Heading,
  Button,
  spacingL,
  Container,
} from "@RooUI";
import styled from "styled-components";
import { Image } from "antd";
import { VStack } from "../Flex";
import { RooIcon } from "../RooIcon";
import { useFavoriteHospital } from "../shiftDetails/shiftDetails/useFavoriteHospital";
import { useDeleteShiftProposal } from "@RooBeta/hooks/api/useDeleteShiftProposal";
import { useCreateShiftProposal } from "@RooBeta/hooks/api/useCreateShiftProposal";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";
import { Event, EventTracker } from "@Roo/tracking/service/EventTracker/EventTrackerService";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { isRooFeatureEnabled } from "@Roo/Common/Wrappers/isRooFeatureEnabled";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";

export const HospitalShiftProposal = ({ hospitalId }: { hospitalId: number }) => {
  const { vetId } = useRequiredAuthorizedUser();
  const favoriteHospital = useFavoriteHospital({ hospitalId });
  const { mutate: deleteShiftProposal } = useDeleteShiftProposal();
  const { mutate: createShiftProposal } = useCreateShiftProposal();
  const { data: shiftProposals } = useGetShiftProposals("active");
  const shiftProposalId = shiftProposals?.find(
    (proposal) => proposal.hospitalId === hospitalId
  )?.shiftProposalId;
  const hasShiftProposal = typeof shiftProposalId === "number";
  const isShiftProposalEnabled = isRooFeatureEnabled(FEATURE_FLAGS.SHIFT_PROPOSAL);

  const ref = useImpressionTracking({
    event: {
      eventName: Event.Name.VET_SHIFT_PROPOSAL_HOSPITAL_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET,
      entityId: vetId ?? undefined,
      context: {
        hospitalId,
        source: "hospitalAllShifts",
      },
    },
  });

  const onClickInterest = () => {
    if (hospitalId) {
      if (hasShiftProposal) {
        EventTracker.send({
          eventName: Event.Name.VET_SHIFT_PROPOSAL_REMOVE,
          eventType: Event.Type.CLICK,
          entityType: Event.Entity.VET,
          entityId: vetId ?? undefined,
          context: {
            hospitalId,
            source: "hospitalAllShifts",
          },
        });
        deleteShiftProposal({ shiftProposalId });
      } else {
        EventTracker.send({
          eventName: Event.Name.VET_SHIFT_PROPOSAL_SUBMIT,
          eventType: Event.Type.CLICK,
          entityType: Event.Entity.VET,
          entityId: vetId ?? undefined,
          context: {
            hospitalId,
            source: "hospitalAllShifts",
          },
        });
        createShiftProposal({ hospitalId });
      }
    }
  };

  return (
    <Container ref={ref} backgroundColor={grayLightest} borderColor={grayLightest}>
      <VStack $alignItems="center" $gap="m">
        <Image
          src={pawPrintCircle}
          width={48}
          height={48}
          preview={false}
          alt="paw print"
          data-testid="pawPrintImage"
        />
        <TextContainer>
          <Heading level={5} color={grayBase}>
            Ruff, looks like they're all out of shifts
          </Heading>
          <Body>
            Favorite this hospital to get notified when they post new shifts, or let them know
            you're interested and we'll ask them to post more shifts.
          </Body>
        </TextContainer>
        <VStack $gap="l" $justifyContent="center" $alignItems="center" $width="100%">
          <Button onClick={favoriteHospital.toggle} data-testid="favoriteButton" $fullWidth>
            <RooIcon
              icon={favoriteHospital.isFavorited ? "favorite_filled" : "favorite"}
              data-testid={`rooIconFavorite${favoriteHospital.isFavorited ? "Filled" : ""}`}
            />
            Favorite
          </Button>
          {typeof vetId === "number" && isShiftProposalEnabled ? (
            <Button data-testid="interestedButton" onClick={onClickInterest} $fullWidth>
              {hasShiftProposal ? <RooIcon icon="check" data-testid="rooIconCheck" /> : null}
              I'm Interested
            </Button>
          ) : null}
        </VStack>
      </VStack>
    </Container>
  );
};

const Body = styled.div`
  ${BodyText}
  color: ${grayLight400};
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${spacingL};
  text-align: center;
`;
