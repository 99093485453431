import React from "react";
import { purpleBase, Button } from "@RooUI";
import { VStack } from "../Flex";
import { RooIcon } from "../RooIcon";
import {
  CustomCardDetails,
  CustomCardDetailsWrapper,
  CustomShiftMetadata,
  ButtonContainer,
} from "./ShiftProposalHospitalList";
import {
  Card,
  CardLogo,
  CardLogoIcon,
  HospitalImage,
  DefaultHospitalIcon,
  HospitalName,
} from "./styles";
import { WorkedHospital } from "@Roo/hooks/api/useGetWorkedHospitals";
import { useCreateShiftProposal } from "@RooBeta/hooks/api/useCreateShiftProposal";
import { useDeleteShiftProposal } from "@RooBeta/hooks/api/useDeleteShiftProposal";
import { useGetShiftProposals } from "@RooBeta/hooks/api/useGetShiftProposals";
import { useImpressionTracking } from "@RooBeta/hooks/tracking/useImpressionTracking";
import { Event, EventTracker } from "@RooBeta/types";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";

export const ShiftProposalHospitalCard = ({ hospital }: { hospital: WorkedHospital }) => {
  const { vetId } = useRequiredAuthorizedUser();

  const fullAddress = (hospital: WorkedHospital) =>
    `${hospital.hospitalAddress}, ${hospital.hospitalCity}, ${hospital.stateCode}`;

  const { mutate: createShiftProposal } = useCreateShiftProposal();

  const { mutate: deleteShiftProposal } = useDeleteShiftProposal();

  const { data: activeShiftProposals } = useGetShiftProposals("active");
  const shiftProposalsMap = activeShiftProposals?.reduce((acc, proposal) => {
    acc[proposal.hospitalId] = proposal.shiftProposalId;
    return acc;
  }, {} as Record<number, number>);

  const ref = useImpressionTracking({
    event: {
      eventName: Event.Name.VET_SHIFT_PROPOSAL_HOSPITAL_CARD_VIEW,
      eventType: Event.Type.IMPRESSION,
      entityType: Event.Entity.VET,
      entityId: vetId ?? undefined,
      context: {
        hospitalId: hospital.hospitalId,
        source: "shiftList",
      },
    },
  });

  return (
    <Card
      key={hospital.hospitalId}
      data-testid={`hospitalcard${hospital.hospitalId}`}
      $variant="action_list"
      ref={ref}
    >
      <CustomCardDetails>
        <CustomCardDetailsWrapper>
          <CardLogo>
            {hospital.isFavorite ? (
              <CardLogoIcon>
                <RooIcon
                  icon="favorite_filled"
                  size="s"
                  color={purpleBase}
                  data-testid="rooIconFavoriteFilled"
                />
              </CardLogoIcon>
            ) : null}
            {hospital.hospitalProfileImagePath ? (
              <HospitalImage src={hospital.hospitalProfileImagePath} alt={hospital.hospitalName} />
            ) : (
              <DefaultHospitalIcon>
                <RooIcon icon="business" size="l" data-testid="rooIconBusiness" />
              </DefaultHospitalIcon>
            )}
          </CardLogo>
          <VStack $gap="xxs">
            <HospitalName>{hospital.hospitalName}</HospitalName>
            <CustomShiftMetadata>{fullAddress(hospital)}</CustomShiftMetadata>
          </VStack>
        </CustomCardDetailsWrapper>
        <ButtonContainer>
          <Button
            $buttonType="secondary"
            size="small"
            data-testid={`availablebutton${hospital.hospitalId}`}
            onClick={() => {
              if (shiftProposalsMap?.[hospital.hospitalId]) {
                EventTracker.send({
                  eventName: Event.Name.VET_SHIFT_PROPOSAL_REMOVE,
                  eventType: Event.Type.CLICK,
                  entityType: Event.Entity.VET,
                  entityId: vetId ?? undefined,
                  context: {
                    hospitalId: hospital.hospitalId,
                    source: "shiftList",
                  },
                });
                deleteShiftProposal({
                  shiftProposalId: shiftProposalsMap[hospital.hospitalId],
                });
              } else {
                EventTracker.send({
                  eventName: Event.Name.VET_SHIFT_PROPOSAL_SUBMIT,
                  eventType: Event.Type.CLICK,
                  entityType: Event.Entity.VET,
                  entityId: vetId ?? undefined,
                  context: {
                    hospitalId: hospital.hospitalId,
                    source: "shiftList",
                  },
                });
                createShiftProposal({ hospitalId: hospital.hospitalId });
              }
            }}
          >
            {shiftProposalsMap?.[hospital.hospitalId] ? (
              <RooIcon icon="check" size="m" color={purpleBase} data-testid="rooIconCheck" />
            ) : null}
            I'm Interested
          </Button>
        </ButtonContainer>
      </CustomCardDetails>
    </Card>
  );
};
