import React from "react";

import { VStack } from "@RooBeta/components";

import { EmptyListContainer, StyledScrollArea } from "./styles";
import { ShiftProposalHospitalList } from "./ShiftProposalHospitalList";
import { NoShifts } from "./NoShifts";
import { NAVIGATION_HEIGHT, SEARCH_HEIGHT } from "@RooBeta/constants";
import { GlobalNotificationCard } from "../globalNotificationCard";

export const EmptyList = () => {
  return (
    <StyledScrollArea $height={window.innerHeight - SEARCH_HEIGHT - NAVIGATION_HEIGHT} $fixedHeight>
      <GlobalNotificationCard />
      <EmptyListContainer>
        <VStack $justifyContent="center" $alignItems="center" $gap="m" $width="100%">
          <NoShifts />
          <ShiftProposalHospitalList />
        </VStack>
      </EmptyListContainer>
    </StyledScrollArea>
  );
};
