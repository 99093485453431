import { PROMO_UTM_MEDIUM, PROMO_UTM_SOURCE } from "../constants/PromoConstants";

export const getFormattedDate = (date: string) =>
  new Intl.DateTimeFormat("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }).format(new Date(date));

export const cleanBaseRoute = (route: string) => route.replace(/promos.*/, "promos");

export const getClaimPromoRequestBody = (userId: number, promoId: number) => ({
  user_id: userId,
  promo_id: promoId,
  shifts_requested: 0,
  shifts_completed: 0,
  promo_started_date: Date(),
  promo_completed_date: null,
  promo_payout_date: null,
  is_promo_complete_message: 0,
  utm_source: localStorage.getItem("utm_source") || PROMO_UTM_SOURCE.WEB_PROMO_BANNER,
  utm_campaign: localStorage.getItem("utm_campaign") || "",
  utm_medium: PROMO_UTM_MEDIUM.WEB,
});
