import { purpleBase } from "@RooUI";
import React from "react";

import { RooIcon } from "@RooBeta/components";
import { Icon } from "@RooBeta/types";

import {
  AnchorStyled,
  DrawerHeaderGroup,
  DrawerNavigationItem,
  LinkStyled,
  ListItemStyled,
  UnorderedListStyled,
} from "./styles";
import { LinkProps } from "react-router-dom";

export interface NavItemProps extends Omit<LinkProps, "to"> {
  icon: Icon;
  onClick?: () => void;
  children: React.ReactNode;
  active?: boolean;
  to?: LinkProps["to"];
}

const Item = ({ icon, to, onClick, children, active, target, rel }: NavItemProps) => {
  const content = (
    <ListItemStyled>
      <DrawerNavigationItem $active={active} data-active={active}>
        <RooIcon icon={icon} size="l" />
        {children}
      </DrawerNavigationItem>
    </ListItemStyled>
  );

  if (to?.toString().includes("http")) {
    return (
      <AnchorStyled
        href={to.toString()}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content}
      </AnchorStyled>
    );
  }

  if (to) {
    return (
      <LinkStyled to={to} onClick={onClick} target={target} rel={rel}>
        {content}
      </LinkStyled>
    );
  }

  return (
    <div role="button" onClick={onClick} style={{ cursor: "pointer" }}>
      {content}
    </div>
  );
};

export interface GroupProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

const Group = ({ label, children, className }: GroupProps) => (
  <div className={className}>
    <DrawerHeaderGroup level={4} color={purpleBase}>
      {label}
    </DrawerHeaderGroup>
    <UnorderedListStyled>{children}</UnorderedListStyled>
  </div>
);

export interface MainProps {
  children: React.ReactNode;
  label: string;
}

const Main = ({ children, label }: MainProps) => {
  return (
    <nav aria-label={label}>
      <UnorderedListStyled>{children}</UnorderedListStyled>
    </nav>
  );
};

// Attach sub-components
const DrawerNavigation = {
  Item,
  Group,
  Main,
};

// Export everything
export { DrawerNavigation };
