import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enAdminTranslations from "../../locales/en/admin.json";
import enCommonTranslations from "../../locales/en/common.json";
import enModalTranslations from "../../locales/en/modal.json";
import enOnboardingTranslations from "../../locales/en/onboarding.json";
import enPromoTranslations from "../../locales/en/promos.json";
import enRegistrationTranslations from "../../locales/en/registration.json";
import enReferralsTranslations from "../../locales/en/referrals.json";
import enVetStudentModalTranslations from "../../locales/en/vetStudentModal.json";

type EnCommonTranslations = typeof enCommonTranslations;
type EnAdminTranslations = typeof enAdminTranslations;
type EnOnboardingTranslations = typeof enOnboardingTranslations;
type EnPromoTranslations = typeof enPromoTranslations;
type EnRegistrationTranslations = typeof enRegistrationTranslations;
type EnModalTranslations = typeof enModalTranslations;
type EnReferralsTranslations = typeof enReferralsTranslations;
type EnVetStudentModalTranslations = typeof enVetStudentModalTranslations;

type EnTranslationResources = {
  admin: EnAdminTranslations;
  common: EnCommonTranslations;
  onboarding: EnOnboardingTranslations;
  promos: EnPromoTranslations;
  registration: EnRegistrationTranslations;
  modal: EnModalTranslations;
  referrals: EnReferralsTranslations;
  vetStudent: EnVetStudentModalTranslations;
};

export type NestedTranslationKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedTranslationKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type RooTranslationKey = NestedTranslationKeyOf<EnTranslationResources>;

i18n.use(initReactI18next);

export const initI18n = () => {
  // We're not using the browser language detector.
  const debugMode = process.env.NODE_ENV === "development";
  i18n.init({
    fallbackLng: "en",
    debug: debugMode,
    resources: {
      en: {
        translation: {
          admin: enAdminTranslations,
          common: enCommonTranslations,
          promos: enPromoTranslations,
          onboarding: enOnboardingTranslations,
          registration: enRegistrationTranslations,
          modal: enModalTranslations,
          referrals: enReferralsTranslations,
          vetStudent: enVetStudentModalTranslations,
        },
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
};
