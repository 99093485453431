import { StatusAlert } from "@RooUI";
import React from "react";
import styled from "styled-components";

import { Event, EventTracker } from "@Roo/tracking/service/EventTracker/EventTrackerService";

import { useRooTranslation } from "../Wrappers/useRooTranslation";

interface MicroDepositStatusAlertProps {
  hostedVerificationUrl: string;
  providerType: "vet" | "tech";
  providerId: number;
}
export const MicroDepositStatusAlert = ({
  hostedVerificationUrl,
  providerType,
  providerId,
}: MicroDepositStatusAlertProps) => {
  const { t } = useRooTranslation();

  const handleClick = async () => {
    await EventTracker.send({
      eventName: Event.Name.PROVIDER_ENTER_BANK_MICRO_DEPOSIT_BANNER,
      eventType: Event.Type.CLICK,
      entityType: providerType === "vet" ? Event.Entity.VET : Event.Entity.TECH,
      entityId: providerId,
    });
    window.open(hostedVerificationUrl, "_blank");
  };

  return (
    <Container>
      <StatusAlert
        text={t("common.pendingStripeBanks.microDepositModal.text")}
        statusIcon={"AttachMoney"}
        secondaryCTAProps={{
          title: "Verify Deposit",
          onClick: handleClick,
          "data-testid": "verifyDepositBtn",
        }}
        data-testid="microDepositStatusAlert"
      />
    </Container>
  );
};

const Container = styled.div``;
