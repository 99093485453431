import {
  SmallMediumBodyText,
  blueDark600,
  blueLightest,
  grayWhite,
  inputBorderRadius,
  purpleLight50,
  spacingS,
  spacingXS,
} from "@RooUI";
import React from "react";
import { styled } from "styled-components";

type SegmentedControlOption<T> = {
  label: string | React.ReactNode;
  value: T;
  "data-testid"?: string;
};

type SegmentedControlProps<T> = Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> & {
  options: SegmentedControlOption<T>[];
  selected?: T | T[];
  onChange: (value: T) => void;
  "data-testid"?: string;
  variant?: SegmentedControlVariant;
};

type SegmentedControlVariant = "default" | "contrast";

export const SegmentedControl = <T extends string>({
  options,
  selected,
  onChange,
  variant = "default",
  "data-testid": dataTestId,
  ...props
}: SegmentedControlProps<T>) => (
  <SegmentedControlContainer data-testid={dataTestId} {...props}>
    {options.map((option) => (
      <SegmentedControlButton
        $selected={
          Array.isArray(selected) ? selected.includes(option.value) : selected === option.value
        }
        $variant={variant}
        key={option.value}
        onClick={() => onChange(option.value)}
        data-testid={option["data-testid"] ? `${option["data-testid"]}` : "segmentedControlBtn"}
      >
        {option.label}
      </SegmentedControlButton>
    ))}
  </SegmentedControlContainer>
);

const SegmentedControlContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${inputBorderRadius};
  border: 2px solid ${blueLightest};
  overflow: hidden;
`;

const SegmentedControlButton = styled.button<{
  $selected: boolean;
  $variant: SegmentedControlVariant;
}>`
  flex: 1;
  ${SmallMediumBodyText}
  color: ${({ $selected, $variant }) =>
    $variant === "contrast" && $selected ? grayWhite : blueDark600};
  background-color: ${({ $selected, $variant }) =>
    $selected && $variant === "contrast" ? blueDark600 : $selected ? purpleLight50 : grayWhite};
  border: none;
  border-right: 2px solid ${blueLightest};
  padding: ${spacingXS} ${spacingS};
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  &:focus {
    outline: none;
  }
`;
