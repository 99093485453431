import React, { FC, ReactNode } from "react";

import { Link, LinkTextProps } from "../Link";

import { DropDownProps, DropDownStyled } from "./DropDownStyled";

export type DropDownPropTypes = Omit<
  DropDownProps,
  "items" | "children" | "menu" | "overlayStyle"
> & {
  items: LinkTextProps[];
  children: ReactNode;
};

export const DropDown: FC<DropDownPropTypes> = ({ items, children, ...props }) => {
  const itemsFormatted = items.map((item, i) => ({
    key: i,
    label: <Link {...item} data-testid={item["data-testid"]} />,
  }));
  return (
    <DropDownStyled
      menu={{ items: itemsFormatted }}
      overlayStyle={{ maxWidth: 300, minWidth: 150 }}
      {...props}
    >
      {children}
    </DropDownStyled>
  );
};
