import React from "react";

import { Color } from "../../styles/constants";

import { ErrorText as ErrorTextComponent } from "./ErrorText";
import {
  BoldText as BoldTextComponent,
  MediumText as MediumTextComponent,
  SmallMediumText as SmallMediumTextComponent,
  SmallBoldText as SmallBoldTextComponent,
  SmallText as SmallTextComponent,
  Text as TextComponent,
  LargeText as LargeTextComponent,
  LargeMediumText as LargeMediumTextComponent,
  LargeBoldText as LargeBoldTextComponent,
} from "./TextStyles";

export interface TextProps {
  children?: React.ReactNode;
  className?: string;
  "data-testid"?: string;
  color?: Color;
  disabled?: boolean;
  error?: boolean;
}

export const Text: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <TextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </TextComponent>
  );
};

export const BoldText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <BoldTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </BoldTextComponent>
  );
};

export const MediumText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <MediumTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </MediumTextComponent>
  );
};

export const SmallText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <SmallTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </SmallTextComponent>
  );
};

export const ErrorText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <ErrorTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </ErrorTextComponent>
  );
};

export const SmallMediumText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <SmallMediumTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </SmallMediumTextComponent>
  );
};

export const SmallBoldText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <SmallBoldTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </SmallBoldTextComponent>
  );
};

export const LargeText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <LargeTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </LargeTextComponent>
  );
};

export const LargeMediumText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <LargeMediumTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </LargeMediumTextComponent>
  );
};

export const LargeBoldText: React.FC<TextProps> = (props) => {
  const { "data-testid": dataTestId } = props;
  return (
    <LargeBoldTextComponent data-testid={dataTestId} className={props.className} {...props}>
      {props.children}
    </LargeBoldTextComponent>
  );
};
