import styled from "styled-components";

import {
  SmallBodyText,
  disabledTextColor,
  disabledTextDark,
  grayBase,
  smallMediaBreakpoint,
  spacingL,
  spacingM,
} from "../../styles/constants";
import { FormLabel } from "../FormLabel";
import { TwoColumnGrid } from "../Grid";

import { RadioGroupProps } from "./RadioGroup";

export const RadioGroupGrid = styled(TwoColumnGrid)<{ $rowGap?: string; $marginTop?: string }>`
  row-gap: ${({ $rowGap }) => ($rowGap ? $rowGap : "0px")};
  margin-top: ${({ $marginTop }) => ($marginTop ? $marginTop : "0px")};
`;

export const SingleColumnDiv = styled.div<Pick<RadioGroupProps, "$shouldWrapRadios">>`
  gap: ${spacingM};
  ${(props) => `${getWrappedRadiosStyle(props.$shouldWrapRadios)}`};
`;

const getWrappedRadiosStyle = (shouldWrapRadios?: boolean) => {
  if (!shouldWrapRadios) {
    return "";
  }
  return `
      display: inline-flex;
      flex-wrap: wrap;
      gap: ${spacingL}
  `;
};

export const StyledFormLabel = styled(FormLabel)`
  color: ${({ $disabled }) => ($disabled ? disabledTextDark : "inherit")};
`;

export const RadioGroupFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacingL};
  align-items: center;
  @media (max-width: ${smallMediaBreakpoint}) {
    align-items: unset;
  }
`;

export const RadioGroupSubText = styled.div<{ $disabled: boolean }>`
  ${SmallBodyText};
  word-wrap: break-word;
  white-space: normal;
  color: ${({ $disabled }) => ($disabled ? disabledTextColor : grayBase)};
`;
