import { useEffect } from "react";

import {
  useContractStatusQuery,
  useHospitalInfoQuery,
  useHospitalRatingsQuery,
  useRequiredAuthorizedUser,
  useShiftDetailsQuery,
} from "@RooBeta/hooks";
import { useShiftDetailsStore } from "@RooBeta/store";

export const useShiftDetails = () => {
  const {
    setShiftDetails,
    setHospitalInfo,
    setHospitalRatings,
    setContractStatus,
    setIsLoading,
    setLoading,
    setErrors,
    shiftGroupId,
    setHospitalId,
    hospitalId,
  } = useShiftDetailsStore();

  const { vetId } = useRequiredAuthorizedUser();

  const shiftDetails = useShiftDetailsQuery({ shiftGroupId, refetchOnWindowFocus: false });
  const hospitalInfo = useHospitalInfoQuery({ hospitalId, refetchOnWindowFocus: false });
  const hospitalRatings = useHospitalRatingsQuery({ hospitalId, refetchOnWindowFocus: false });

  // @TODO: If we include `enterpriseId` the shift search payload then
  // we can execute this query in parallel with the queries above.
  const contractStatus = useContractStatusQuery({
    contractName: "MARS_NDA",
    enterpriseId: hospitalInfo.data?.enterpriseId,
    refetchOnWindowFocus: false,
    enabled: !!vetId && !!hospitalInfo.data?.enterpriseId,
  });

  useEffect(() => {
    setShiftDetails(shiftDetails.data);
    setHospitalInfo(hospitalInfo.data);
    setHospitalRatings(hospitalRatings.data?.data);
    setContractStatus(contractStatus.data?.data);
    setIsLoading(
      shiftDetails.isFetching ||
        hospitalInfo.isLoading ||
        hospitalRatings.isLoading ||
        contractStatus.isLoading
    );

    setLoading({
      shiftDetails: shiftDetails.isFetching,
      hospitalInfo: hospitalInfo.isLoading,
      hospitalRatings: hospitalRatings.isLoading,
      contractStatus: contractStatus.isLoading,
    });
    setErrors({
      shiftDetails: shiftDetails.error?.message,
      hospitalInfo: hospitalInfo.error?.message,
      hospitalRatings: hospitalRatings.error?.message,
      contractStatus: contractStatus.error?.message,
    });
  }, [
    // shiftDetails
    shiftDetails.data,
    shiftDetails.isFetching,
    shiftDetails.error?.message,
    setShiftDetails,
    // hospitalInfo
    hospitalInfo.data,
    hospitalInfo.isLoading,
    hospitalInfo.error?.message,
    setHospitalInfo,
    // hospitalRatings
    hospitalRatings.data?.data,
    hospitalRatings.isLoading,
    hospitalRatings.error?.message,
    setHospitalRatings,
    // contractStatus
    contractStatus.data?.data,
    contractStatus.isLoading,
    contractStatus.error?.message,
    setContractStatus,
    // Loading / Errors
    setIsLoading,
    setLoading,
    setErrors,
  ]);

  useEffect(() => {
    if (shiftDetails.data?.hospitalId) {
      setHospitalId(shiftDetails.data?.hospitalId);
    }
  }, [shiftDetails.data?.hospitalId, setHospitalId]);

  return { shiftGroupId };
};
