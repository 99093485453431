import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";

import { Promo } from "@roo-dev/roo-node-types";

import { APIError, post } from "../../api/common/rooFetch";
import { useModal } from "../../Common/Modals/hooks/useModal";
import { PromoPreClaimShiftsModal } from "../../Common/PromoPreClaimShiftsModal";
import { ToastContext } from "../../Common/Toasts/context/ToastContext";
import { useRooTranslation } from "../../Common/Wrappers/useRooTranslation";
import { createPromoTracking } from "../../constants/apiRoutes";
import { getClaimPromoRequestBody } from "../../Promos/utils";
import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";

import { useGetUserPromos } from "./useGetUserPromos";

interface CreatePromoTrackingParams {
  userId: number;
  promoId: number;
}

interface UseCreatePromoTrackingProps {
  onMutate?: (variables: CreatePromoTrackingParams) => void;
  onSuccess?: (promoId: number) => void;
  userId: number;
  isNewExperience?: boolean;
}

export const useCreatePromoTracking = ({
  onMutate,
  onSuccess,
  userId,
  isNewExperience = false,
}: UseCreatePromoTrackingProps) => {
  const { refetch: refetchUserPromos } = useGetUserPromos(userId);
  const { openModal } = useModal();
  const { showSuccessToast, showErrorToast } = useContext(ToastContext);
  const { t } = useRooTranslation();

  return useMutation<number, APIError<{ message: string }>, CreatePromoTrackingParams>(
    async (params) => {
      const createPromoTrackingData = getClaimPromoRequestBody(params.userId, params.promoId);
      const result = await post(createPromoTracking(), createPromoTrackingData);
      if (result) {
        return params.promoId;
      } else {
        throw new Error("Failed to create promo tracking");
      }
    },
    {
      onMutate,
      onSuccess: async (claimedPromoId) => {
        EventTracker.send({
          eventName: Event.Name.PROMO_MODAL_CLAIM_OFFER,
          eventType: Event.Type.CLICK,
          entityType: Event.Entity.PROMO,
          entityId: claimedPromoId,
          context: {
            isNewExperience,
          },
        });
        const { data: promos } = await refetchUserPromos();
        const claimedUserPromo = promos?.find(
          (promo) => promo.state === Promo.PromoState.STARTED && promo.promoId === claimedPromoId
        );
        if (
          !claimedUserPromo ||
          (claimedUserPromo.shiftsRequested === 0 &&
            !claimedUserPromo.childrenPromos?.some((childPromo) => childPromo.shiftsRequested > 0))
        ) {
          showSuccessToast({
            message: t("promos.eligibleCard.successToast.message"),
            description: t("promos.eligibleCard.successToast.description"),
          });
        } else {
          openModal(PromoPreClaimShiftsModal, {
            claimedPromoName: claimedUserPromo.promoName,
            claimedPromoId: claimedUserPromo.promoId,
          });
        }
        if (onSuccess) onSuccess(claimedPromoId);
      },
      onError: () => {
        showErrorToast({
          description: t("promos.eligibleCard.errorToast.description"),
          message: t("promos.eligibleCard.errorToast.message"),
        });
      },
    }
  );
};
