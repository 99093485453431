import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import { useCreatePromoTracking } from "@Roo/hooks/api/useCreatePromoTracking";
import { useGetUserPromos } from "@Roo/hooks/api/useGetUserPromos";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { Event, EventTracker } from "@RooBeta/types";

export const usePromoManagement = () => {
  const { userId } = useRequiredAuthorizedUser();
  const { promos } = useGetUserPromos(userId);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [dismissedPromos, setDismissedPromos] = useLocalStorage<number[]>("dismissed-promos", []);

  const claimPromoMutation = useCreatePromoTracking({
    userId,
    onMutate: () => setShowLoading(true),
    onSuccess: () => {
      setShowModal(false);
      setShowLoading(false);
    },
    isNewExperience: true,
  });

  const handleOpenModal = (promoId: number) => {
    EventTracker.send({
      eventName: Event.Name.PROMO_BANNER_REVIEW_OFFER,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.PROMO,
      entityId: promoId,
      context: { isNewExperience: true },
    });
    setShowModal(true);
  };

  const handleDismiss = (promoId: number) => {
    setDismissedPromos([...dismissedPromos, promoId]);
  };

  const eligiblePromos = promos?.eligible?.filter(
    (promo) => !dismissedPromos.includes(promo.promoId)
  );

  return {
    showModal,
    showLoading,
    dismissedPromos,
    claimPromoMutation,
    handleOpenModal,
    handleDismiss,
    setShowModal,
    promoReminder: promos?.started?.find((promo) => promo.showReminder),
    eligiblePromos,
    hasEligiblePromos: (eligiblePromos?.length ?? 0) > 0,
  };
};
