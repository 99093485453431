import { Drawer, DrawerProps } from "antd";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useShiftDetailsStore, useViewport } from "@RooBeta/store";
import { ViewportSize } from "@RooBeta/types";
import { getReturnPath } from "@RooBeta/utils";

import { ShiftDetailsFooter } from "./ShiftDetailsFooter";
import { ShiftDetailsHeader } from "./ShiftDetailsHeader";
import { ShiftDetailsTabs } from "./ShiftDetailsTabs";
import { useShiftDetails } from "./useShiftDetails";

interface ShiftDetailsParams {
  shiftGroupId?: string;
}

const STYLES: DrawerProps["styles"] = {
  body: { padding: 0, scrollbarGutter: "stable" },
  footer: { padding: 0, borderTop: "none" },
};

export const ShiftDetailsDrawer = () => {
  const viewport = useViewport();
  const params = useParams<ShiftDetailsParams>();
  const { setShiftGroupId, tab, setTab, reset } = useShiftDetailsStore();

  const { shiftGroupId } = useShiftDetails();
  const history = useHistory();
  useEffect(() => setTab("info"), [shiftGroupId, setTab]);

  useEffect(() => {
    if (params.shiftGroupId && !isNaN(+params.shiftGroupId)) {
      setShiftGroupId(+params.shiftGroupId);
    }
  }, [params.shiftGroupId, setShiftGroupId]);

  const handleClose = () => {
    reset();
    history.push(getReturnPath());
  };

  return (
    <Drawer
      data-testid="sidePanelContainer"
      open={!!shiftGroupId}
      onClose={handleClose}
      placement={viewport === ViewportSize.s ? "bottom" : "right"}
      height={viewport === ViewportSize.s ? "100dvh" : undefined}
      closeIcon={false}
      footer={["info", "hospital"].includes(tab) ? <ShiftDetailsFooter /> : undefined}
      styles={STYLES}
    >
      <ShiftDetailsHeader />
      <ShiftDetailsTabs />
    </Drawer>
  );
};
