import {
  purpleBase,
  purpleLight50,
  spacingL,
  spacingM,
  BodyText,
  grayLight400,
  Heading,
  Container,
  spacingXXL,
} from "@RooUI";
import React from "react";
import styled from "styled-components";
import { VStack } from "@RooBeta/components";
import { CardDetails, CardDetailsWrapper, ShiftMetadata } from "./styles";
import { useGetWorkedHospitals } from "@Roo/hooks/api/useGetWorkedHospitals";
import { ShiftProposalHospitalCard } from "./ShiftProposalHospitalCard";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { isRooFeatureEnabled } from "@Roo/Common/Wrappers/isRooFeatureEnabled";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";

export const ShiftProposalHospitalList = () => {
  const { vetId } = useRequiredAuthorizedUser();
  const { data: workedHospitals } = useGetWorkedHospitals(1, {
    enabled: typeof vetId === "number",
  });

  const isShiftProposalEnabled = isRooFeatureEnabled(FEATURE_FLAGS.SHIFT_PROPOSAL);

  if (
    typeof vetId !== "number" ||
    !workedHospitals ||
    !workedHospitals.length ||
    !isShiftProposalEnabled
  )
    return null;

  return (
    <Container
      backgroundColor={purpleLight50}
      borderColor={purpleLight50}
      style={{
        width: "100%",
        padding: spacingXXL,
      }}
      data-testid="shiftProposalHospitalList"
    >
      <VStack $gap="l">
        <VStack $gap="xs" $alignItems="center">
          <Heading level={5} color={purpleBase}>
            Let hospitals know you're available
          </Heading>
          <Body>We'll ask your favorite hospitals to post more shifts</Body>
        </VStack>
        <VStack>
          {workedHospitals.slice(0, 5).map((hospital) => (
            <ShiftProposalHospitalCard key={hospital.hospitalId} hospital={hospital} />
          ))}
        </VStack>
      </VStack>
    </Container>
  );
};

export const ButtonContainer = styled.div`
  margin-left: auto;
  padding-left: ${spacingL};

  @media (max-width: 768px) {
    margin-left: 0;
    padding-left: 0;
    margin-top: ${spacingM};
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const CustomCardDetails = styled(CardDetails)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Body = styled.div`
  ${BodyText}
  color: ${grayLight400};
`;

export const CustomCardDetailsWrapper = styled(CardDetailsWrapper)`
  flex: 1;
`;

export const CustomShiftMetadata = styled(ShiftMetadata)`
  white-space: wrap;
  word-break: break-word;
`;
