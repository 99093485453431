import React from "react";

export enum NotificationCardPriority {
  PROMO_REMINDER = 1,
  ELIGIBLE_PROMO = 2,
  MICRO_DEPOSIT_STATUS = 3,
  PROMO_PROPOSAL_BANNER = 4,
  DEFAULT = 999,
}

export interface NotificationCard {
  type: string;
  priority: NotificationCardPriority;
  component: React.ReactElement;
}
