import { spacing, tealDark600, tealLightest } from "@RooUI";
import styled from "styled-components";

export const ClaimPromoCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.m};
  background-color: ${tealLightest};
  padding: ${spacing.l};
`;

export const IconContainer = styled.div`
  background-color: ${tealDark600};
  border-radius: 30px;
  padding: ${spacing.s};
`;

export const PromoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${spacing.m};
`;
