import { PROMO_TYPE } from "./PromoConstants";

export const radioPromoType = [
  { value: 0, label: PROMO_TYPE.ACTIVATION },
  { value: 1, label: PROMO_TYPE.ACQUISITION },
  { value: 2, label: PROMO_TYPE.LIFECYCLE },
  { value: 3, label: PROMO_TYPE.STREAK },
  { value: 4, label: PROMO_TYPE.RAFFLE },
  { value: 5, label: PROMO_TYPE.MOMENTUM },
];

export const userUploadType = [
  { value: 0, label: "Upload CSV" },
  { value: 1, label: "Deal Desk" },
];

export const RELIEF_WORK_OPTIONS = [
  { value: 1, label: "Full-time relief" },
  { value: 2, label: "Part-time relief" },
  { value: 3, label: "Exploring" },
];
