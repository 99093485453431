import { Button, FlexLayout, MediumText, SmallText, grayWhite } from "@RooUI";
import moment from "moment";
import React from "react";

import { useDismissPromoReminder } from "@Roo/Admin/Promos/hooks/useDismissPromoReminder";
import UserPromo from "@Roo/Promo/types/UserPromo";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { Event, EventTracker } from "@RooBeta/types";

import { RooIcon } from "../";

import { ClaimPromoCardContainer, IconContainer, PromoContent } from "./styles";

interface PromoReminderCardProps {
  promo: UserPromo;
}

export const PromoReminderCard = ({ promo }: PromoReminderCardProps) => {
  const { userId } = useRequiredAuthorizedUser();
  const daysLeft = promo ? moment(promo.shiftRequestByDate).diff(moment(), "days") : 0;
  const { mutate: dismissPromoReminder } = useDismissPromoReminder();
  const handleDismiss = async () => {
    await dismissPromoReminder({ promoId: promo?.promoId, userId });
    EventTracker.send({
      eventName: Event.Name.PROMO_REMINDER_DISMISSED,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.PROMO,
      entityId: promo?.promoId,
      context: {
        ...promo,
        isNewExperience: true,
      },
    });
  };

  return (
    <ClaimPromoCardContainer>
      <PromoContent>
        <MediumText>Don’t let your promo hop away!</MediumText>
        <SmallText>
          “{promo.promoName}” is expiring in {daysLeft} days. You only need to request{" "}
          {promo?.numberOfShifts - promo?.shiftsRequested} more shifts for a big $
          {promo.promoAmount} payout!
        </SmallText>
        <FlexLayout gap="m">
          <Button onClick={handleDismiss}>Got it</Button>
        </FlexLayout>
      </PromoContent>
      <div>
        <IconContainer>
          <RooIcon icon="time" size="l" color={grayWhite} />
        </IconContainer>
      </div>
    </ClaimPromoCardContainer>
  );
};
