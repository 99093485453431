import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { CalendlyView } from "../../../../Common/CalendlyView/CalendlyView";
import PageLoader from "../../../../Common/PageLoader";
import { useCalendlyLink } from "../../../../RegistrationNew/Components/Common/hooks/useCalendlyLink";
import { EventSignupSuccessContainer } from "../common/EventSignUpSuccessContainer";
import { Button, Container, Heading, Link, spacingM, spacingXXL, Text } from "@RooUI";
import { Image } from "antd";
import haveFun from "../../../../static/images/have_fun.svg";
import { VStack } from "@RooBeta/components/Flex";
import { loginRequestAction } from "@Roo/Login/Action/LoginActions";
import { EventHospitalCalendlySignUpProps } from "../hospitalSignUp/types";
import { useAppDispatch } from "@Roo/store/service";
import { updateHospital } from "@Roo/api/hospital";

export type EventCalendlySignUpProps = {
  userId: number;
  vetId: number;
  name: string;
  email: string;
  phone: string;
  onBack?: () => void;
};

export const EventCalendlySignUp = (props: EventCalendlySignUpProps) => {
  const { onBack, ...rest } = props;
  const { calendlyLink, isLoading } = useCalendlyLink(props.userId);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <EventSignupSuccessContainer onClickButton={onBack}>
      <CalendlyView calendlyLink={calendlyLink} {...rest} />
    </EventSignupSuccessContainer>
  );
};

export const EventHospitalCalendlySignUp = (props: EventHospitalCalendlySignUpProps) => {
  const {
    onBack,
    calendlyLink,
    boothSignUp,
    onboardingWithRooTeamMember,
    email,
    password,
    signInRequest,
    hospitalId,
    ...rest
  } = props;
  // if onboardingWithRooTeamMember is true, we don't need to schedule a calendly call
  const [done, setDone] = useState(onboardingWithRooTeamMember);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!done || !onBack) return;

    const updateAndLogin = async () => {
      try {
        await updateHospital({
          hospitalId,
          isRegistrationComplete: 1,
          isForRegistration: 1,
        });
        if (boothSignUp) {
          const timeout = setTimeout(onBack, 10000);
          return () => clearTimeout(timeout);
        }
        dispatch(loginRequestAction({ email, password, signInRequest }));
      } catch (error) {
        Sentry.captureException(error);
      }
      return undefined;
    };
    void updateAndLogin();
    return undefined;
  }, [done, onBack, boothSignUp, dispatch, email, password, signInRequest, hospitalId]);

  return (
    <>
      {done ? (
        <>
          {boothSignUp ? (
            <>
              <Heading level={1}>Roo-Hoo!</Heading>
              <Container>
                <VStack
                  $gap="l"
                  $justifyContent="center"
                  $alignItems="center"
                  style={{ padding: `0 ${spacingXXL} ${spacingXXL}` }}
                >
                  <Image src={haveFun} preview={false} alt="have fun" width={150} height={150} />

                  <Heading level={3}>Thanks For Signing Up For Roo!</Heading>
                  <Text>We look forward to partnering with you soon.</Text>
                  <Button
                    title="Take Me Back"
                    $buttonType="primary"
                    onClick={onBack}
                    data-testid="takeMeBackBtn"
                  />
                </VStack>
              </Container>
            </>
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <>
          <CalendlyView
            calendlyLink={calendlyLink}
            email={email}
            {...rest}
            onEventScheduled={() => setDone(true)}
            tight={true}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              maxWidth: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: spacingM,
            }}
          >
            <Link linkSize="small" onClick={() => setDone(true)}>
              Skip this step
            </Link>
          </div>
        </>
      )}
    </>
  );
};
