import React, { useRef } from "react";
import { Slider, SliderProps } from "@RooUI";

import { useIsVisibleOnScreen } from "../windowUtils";

export const TravelDistanceSlider = (
  props: Omit<SliderProps, "type" | "label" | "range" | "data-testid" | "tooltip"> & {
    tooltip: Omit<SliderProps["tooltip"], "open" | "formatter">;
  }
) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisibleOnScreen(ref);

  return (
    <div ref={ref}>
      {/*  @ts-expect-error -- antd typed their slider props so that value cant be dynamically passedin */}
      <Slider
        {...props}
        type="blue"
        label="Travel distance"
        tooltip={{
          open: isVisible,
          formatter: (v) => `${v} miles`,
          ...props.tooltip,
        }}
        data-testid="travelDistanceSlider"
      />
    </div>
  );
};
