import { MediumText, SmallText, spacingXXL } from "@RooUI";
import React, { useRef } from "react";
import { Milestone } from "@roo-dev/roo-node-types";
import { VList } from "virtua";

import { HStack } from "@RooBeta/components";
import { NAVIGATION_HEIGHT, SEARCH_HEIGHT } from "@RooBeta/constants";
import { useViewportStore } from "@RooBeta/store";
import { GroupedShiftList as List, Provider } from "@RooBeta/types";
import { ShiftCardPointOfEntry } from "@Roo/constants/posthogEventContextTypes";
import { truncate } from "@RooBeta/utils";
import { ShiftCard } from "./ShiftCard";
import { ReliefWorkInterstitial } from "./ReliefWorkInterstitial";
import { StickyHeader, StyledScrollArea } from "./styles";
import { useGetMilestoneStatus, MilestoneStatusResponse } from "../../../hooks/api";
import { useRequiredAuthorizedUser, useSearchRequestBody } from "@RooBeta/hooks";
import { FEATURE_FLAGS } from "@Roo/constants/postHogFeatureFlags";
import { useRooFeatureFlagEnabled } from "@Roo/Common/Wrappers/useRooFeatureFlagEnabled";
import { ShiftProposalHospitalList } from "./ShiftProposalHospitalList";
import { GlobalNotificationCard } from "../globalNotificationCard";

type GroupedShiftListProps = {
  shiftList: List;
  fixedHeight?: boolean;
  page?: string;
};

type RowData = {
  group: List["groups"][number];
  index: number;
  count: number;
  milestoneStatus?: MilestoneStatusResponse;
  isFeatureEnabled: boolean;
  provider: Provider;
  page?: string;
  groups: List["groups"];
};

export const GroupedShiftList = ({ fixedHeight = true, page, ...props }: GroupedShiftListProps) => {
  const isFeatureEnabled =
    useRooFeatureFlagEnabled(FEATURE_FLAGS.CURRENT_SITUATION_PROMPT) ?? false;
  const { provider } = useRequiredAuthorizedUser();
  const proposalListRef = useRef<HTMLDivElement>(null);

  const { windowSize } = useViewportStore();
  const containerHeight = windowSize.height - (NAVIGATION_HEIGHT + SEARCH_HEIGHT);
  const { data: milestoneStatus } = useGetMilestoneStatus({
    milestone: Milestone.UserMilestone.CURRENT_SITUATION_QUESTION,
  });

  const renderRow = (virtualIndex: number) => {
    const group = props.shiftList.groups[virtualIndex];
    const rowData: RowData = {
      group,
      index: virtualIndex,
      count: props.shiftList.count,
      milestoneStatus,
      isFeatureEnabled,
      provider: provider,
      page,
      groups: props.shiftList.groups,
    };
    return <ShiftListRow {...rowData} proposalListRef={proposalListRef} />;
  };

  return (
    <StyledScrollArea
      $height={containerHeight}
      $fixedHeight={fixedHeight}
      data-testid="groupedShiftListScrollArea"
    >
      {() => (
        <div style={{ width: "100%", height: containerHeight }}>
          <VList count={props.shiftList.groups.length} overscan={5}>
            {renderRow}
          </VList>
        </div>
      )}
    </StyledScrollArea>
  );
};

type ShiftListRowProps = RowData & {
  proposalListRef: React.RefObject<HTMLDivElement>;
};

function ShiftListRow({
  group,
  index,
  count,
  milestoneStatus,
  isFeatureEnabled,
  provider,
  page,
  groups,
  proposalListRef,
}: ShiftListRowProps) {
  const shouldShowBanner = (shiftIndex: number) => {
    if (!isFeatureEnabled || milestoneStatus?.data?.completed || provider.providerType !== "VET")
      return false;

    if (page === "schedule") return false;

    const totalShifts = groups.reduce((sum, group) => sum + group.shifts.length, 0);
    const previousShifts = groups
      .slice(0, index)
      .reduce((sum, group) => sum + group.shifts.length, 0);
    const currentShiftNumber = previousShifts + shiftIndex + 1;

    return currentShiftNumber === 4 || (totalShifts < 4 && currentShiftNumber === totalShifts);
  };

  const showShiftProposalHospitalList = index === groups.length - 1 && page !== "schedule";

  const searchRequestBody = useSearchRequestBody();

  return (
    <div>
      {index === 0 && page !== "schedule" && <GlobalNotificationCard />}
      <StickyHeader>
        <HStack $justifyContent="space-between">
          <MediumText>{truncate(group.label)}</MediumText>
          {group.subLabel && <SmallText>{group.subLabel}</SmallText>}
        </HStack>
      </StickyHeader>
      {group.shifts.map((shift, shiftIndex) => {
        const isBannerPosition = shouldShowBanner(shiftIndex);
        const showBanner = isBannerPosition && milestoneStatus?.data?.completed === false;
        return [
          <ShiftCard
            key={`shift-${shift.shiftId}`}
            shift={shift}
            count={count}
            variant="list"
            data-testid={`groupedShiftListCard${shift.shiftId}`}
            pointOfEntry={
              page === "schedule"
                ? ShiftCardPointOfEntry.MyShiftsPane
                : ShiftCardPointOfEntry.SearchShiftsPane
            }
            searchParameters={page === "schedule" ? undefined : searchRequestBody}
            provider={provider}
          />,
          showBanner && <ReliefWorkInterstitial key={`banner-${shift.shiftId}`} />,
        ];
      })}
      {showShiftProposalHospitalList && (
        <div
          ref={proposalListRef}
          style={{
            padding: spacingXXL,
          }}
        >
          <ShiftProposalHospitalList />
        </div>
      )}
    </div>
  );
}
