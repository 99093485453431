import { zodResolver } from "@hookform/resolvers/zod";
import { Drawer, DrawerProps } from "antd";
import React, { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  useNotification,
  useRecalculateShiftPricingQuery,
  useShiftRequestMutation,
} from "@RooBeta/hooks";
import { useShiftDetailsStore, useViewport } from "@RooBeta/store";
import {
  ShiftAdjustments as FormData,
  ViewportSize,
  shiftAdjustmentsSchema as schema,
} from "@RooBeta/types";
import { formatCurrency } from "@RooBeta/utils";

import { ShiftAdjustments } from "./ShiftAdjustments";
import { ShiftAdjustmentsFooter } from "./ShiftAdjustmentsFooter";
import { shiftNotAvailableToasts } from "@Roo/Common/Toasts/constants";

const STYLES: DrawerProps["styles"] = {
  body: { padding: 0, scrollbarGutter: "stable" },
  header: {
    alignSelf: "flex-end",
    borderBottom: "none",
    paddingBottom: "0",
  },
};

export const ShiftAdjustmentsDrawer = () => {
  const viewport = useViewport();
  const notification = useNotification();

  const { shiftDetails, drawer, setDrawer, promoId } = useShiftDetailsStore();
  const open = drawer === "adjust";

  const form = useForm<FormData>({ resolver: zodResolver(schema) });
  const { handleSubmit, watch } = form;

  const bidPrice = watch("bidPrice");
  const requestedStartTime = watch("requestedStartTime");
  const requestedEndTime = watch("requestedEndTime");
  const isRequestWithoutSurgery = watch("requestedWithoutSurgery");

  const pricing = useRecalculateShiftPricingQuery({
    shiftDetails: open ? shiftDetails : undefined,
    flexParams: { requestedStartTime, requestedEndTime, isRequestWithoutSurgery },
  });

  const shiftRequest = useShiftRequestMutation(shiftDetails);

  const onSubmit = handleSubmit(async (data) => {
    const { success, message } = await shiftRequest.mutateAsync({
      flexParams: {
        requestedEndTime: data.requestedEndTime,
        requestedStartTime: data.requestedStartTime,
        bidPrice: data.bidPrice,
        isRequestWithoutSurgery: data.requestedWithoutSurgery,
        shiftPricingChanges: {
          vetShiftAmount: pricing?.data?.vetShiftAmount,
          shiftAmount: pricing?.data?.shiftAmount,
        },
      },
      promoId,
    });

    success
      ? onClose()
      : notification.error({
          message: shiftNotAvailableToasts(message, "").toastMessage,
          description: shiftNotAvailableToasts(message, "").toastDescription,
        });
  });

  const price = pricing.data?.vetShiftAmount
    ? formatCurrency(pricing.data.vetShiftAmount)
    : shiftDetails?.priceFormatted;

  const previousPrice = pricing.data?.vetShiftAmount
    ? shiftDetails?.priceFormatted
    : shiftDetails?.previousPriceFormatted;

  const onClose = useCallback(() => {
    setDrawer(undefined);
  }, [setDrawer]);

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement={viewport === ViewportSize.s ? "bottom" : "right"}
      height={viewport === ViewportSize.s ? "100dvh" : undefined}
      zIndex={1001}
      footer={
        <ShiftAdjustmentsFooter
          price={bidPrice ? formatCurrency(bidPrice) : price}
          previousPrice={previousPrice}
          onCancel={onClose}
          onRequest={onSubmit}
          isPricingLoading={pricing.isFetching}
          isLoading={shiftRequest.isLoading}
        />
      }
      styles={STYLES}
    >
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <ShiftAdjustments />
        </form>
      </FormProvider>
    </Drawer>
  );
};
