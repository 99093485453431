import {
  Avatar,
  BoldText,
  Icon,
  MediumText,
  Popover,
  SmallMediumText,
  SmallText,
  grayWhite,
  purpleBase,
} from "@RooUI";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Conversation, getChatMessages } from "../../api/messages/messages";
import defaultHospitalImage from "../../static/images/default-hosp-img.png";
import vetDefaultImage from "../../static/images/default-vet-img.png";
import { Event, EventTracker } from "../../tracking/service/EventTracker/EventTrackerService";
import { openChatPanel } from "../Chat/actions/chatActions";

import { EmptyState } from "./EmptyState";
import { LoadingState } from "./LoadingState";
import { MessageDate } from "./MesageDate";
import {
  IconBackground,
  IconContainer,
  Message,
  MessageContainer,
  MessageHeading,
  MessageText,
  Messages,
  TotalUnreadCount,
  UnreadMessageCount,
} from "./styles";

type DecoratedConversation = Conversation & {
  unreadMessageCount: number;
  senderName?: string;
};

type ChatNotificationsProps = {
  children?: React.ReactNode;
};

export const ChatNotifications = ({ children }: ChatNotificationsProps) => {
  /**
   * State
   */

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * Redux
   */

  const dispatch = useDispatch();
  const { email, hospitalId, userId } = useSelector((state: any) => state.login);
  const isChatPanelOpen = useSelector((state: any) => state.chatData?.isChatPanelOpen);

  /**
   * Derived
   */

  const unreadCount = useMemo(
    () =>
      conversations.reduce(
        (acc, conversation) =>
          acc +
          conversation.messages.filter(
            ({ read, participant }) => !read && participant.userId !== userId
          ).length,
        0
      ),
    [conversations, userId]
  );

  const decoratedConversations = useMemo(
    () =>
      conversations.map((conversation) => {
        const unreadMessageCount = conversation.messages.filter(
          (message) => !message.read && message.participant.userId !== userId
        ).length;

        const senderName =
          conversation.hospitalName ||
          conversation.participants.find((participant) => participant.userTypeId !== "1")?.name;

        return { ...conversation, unreadMessageCount, senderName };
      }),
    [conversations, userId]
  );

  /**
   * Callbacks
   */

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);

      if (!isOpen) {
        EventTracker.send({
          eventName: Event.Name.CHAT_NOTIFICATIONS_DISMISSED,
          eventType: Event.Type.CLICK,
          entityType: Event.Entity.CONVERSATION,
          entityId: userId,
          context: { hospitalId },
        });
      }
    },
    [userId, hospitalId]
  );

  const onIconClick = useCallback(() => {
    EventTracker.send({
      eventName: Event.Name.CHAT_NOTIFICATIONS_ICON_CLICK,
      eventType: Event.Type.CLICK,
      entityType: Event.Entity.CONVERSATION,
      entityId: userId,
      context: { userId, hospitalId, unreadCount },
    });

    setOpen(!open);
  }, [open, hospitalId, userId, unreadCount]);

  const onMessageClick = useCallback(
    (conversation?: DecoratedConversation) => {
      onOpenChange(false);

      if (!conversation) {
        return;
      }

      const event = conversation.unreadMessageCount
        ? Event.Name.CHAT_NOTIFICATIONS_UNREAD_MESSAGE_CLICK
        : Event.Name.CHAT_NOTIFICATIONS_READ_MESSAGE_CLICK;

      EventTracker.send({
        eventName: event,
        eventType: Event.Type.CLICK,
        entityType: Event.Entity.CONVERSATION,
        entityId: conversation.id,
        context: {
          userId,
          hospitalId,
          unreadMessageCount: conversation.unreadMessageCount,
        },
      });

      if (hospitalId) {
        dispatch(
          openChatPanel({
            contractorUserId: conversation?.participants?.find(
              (participant) => participant.userTypeId !== "1"
            )?.userId,
            hospitalId: hospitalId,
          })
        );
      } else {
        dispatch(
          openChatPanel({
            contractorUserId: userId,
            hospitalId: conversation.hospitalId,
          })
        );
      }
    },
    [onOpenChange, dispatch, hospitalId, userId]
  );

  /**
   * Effects
   */

  // @TODO: We should use `useQuery` for this; it has built-in caching and
  // won't need to be debounced.
  const reload = debounce(() => {
    if (email || hospitalId) {
      // eslint-disable-next-line promise/prefer-await-to-then
      getChatMessages(email, hospitalId).then((conversations) => setConversations(conversations));
    }
  }, 1000);

  useEffect(() => {
    if (email || hospitalId) {
      getChatMessages(email, hospitalId)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then((conversations) => {
          setConversations(conversations);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, hospitalId]);

  useEffect(() => {
    // Listening for new messages and reload.
    window.socket?.on(
      "new-message-notification",
      (data: { vetUserId?: number; hospitalId?: number }) => {
        if (data?.vetUserId === userId || data?.hospitalId === hospitalId) {
          reload();
        }
      }
    );
    window.socket?.on("to-client-message-status", (data: { statusType: string }) => {
      if (data.statusType === "delivered-success") {
        reload();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isChatPanelOpen) {
      // Wait for chat panel to call read API before reloading.
      setTimeout(() => {
        reload();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatPanelOpen]);

  useEffect(() => {
    if (unreadCount > 0) {
      EventTracker.send({
        eventName: Event.Name.CHAT_NOTIFICATIONS_UNREAD_NOTIFICATIONS,
        eventType: Event.Type.IMPRESSION,
        entityType: Event.Entity.CONVERSATION,
        entityId: userId,
        context: { hospitalId, unreadCount },
      });
    }
  }, [unreadCount, hospitalId, userId]);

  return (
    <Popover
      open={open}
      onOpenChange={onOpenChange}
      trigger="click"
      placement="bottom"
      content={
        <Messages>
          {!loading && decoratedConversations.length > 0 ? (
            decoratedConversations.map((conversation) => (
              <Message
                data-testid={`chatRow:${conversation.id}`}
                key={conversation.id}
                onClick={() => onMessageClick(conversation)}
              >
                <Avatar
                  src={
                    conversation.profileImage ||
                    (conversation.messages[0].participant.userTypeId === "1"
                      ? vetDefaultImage
                      : defaultHospitalImage)
                  }
                  size={48}
                />
                <MessageContainer>
                  <div>
                    <MessageHeading>
                      {conversation.unreadMessageCount > 0 ? (
                        <BoldText data-testid="senderName">{conversation.senderName}</BoldText>
                      ) : (
                        <MediumText data-testid="senderName">{conversation.senderName}</MediumText>
                      )}
                      {conversation.unreadMessageCount === 0 && (
                        <MessageDate createdAt={conversation.messages[0].createdAt} />
                      )}
                    </MessageHeading>
                    <MessageText>
                      {conversation.unreadMessageCount > 0 ? (
                        <SmallMediumText data-testid="messagePreview">
                          {conversation.messages[0].text}
                        </SmallMediumText>
                      ) : (
                        <SmallText data-testid="messagePreview">
                          {conversation.messages[0].text}
                        </SmallText>
                      )}
                    </MessageText>
                  </div>
                </MessageContainer>
                {conversation.unreadMessageCount > 0 && (
                  <UnreadMessageCount>
                    <SmallMediumText data-testid="messageCount" color={grayWhite}>
                      {conversation.unreadMessageCount}
                    </SmallMediumText>
                  </UnreadMessageCount>
                )}
              </Message>
            ))
          ) : loading ? (
            <LoadingState />
          ) : (
            <EmptyState />
          )}
        </Messages>
      }
    >
      <IconContainer onClick={onIconClick} data-testid="chatNotificationsNav">
        {children ? (
          <>
            <TotalUnreadCount
              $count={unreadCount}
              $design="new"
              data-testid="chatNotificationsUnreadCount"
            >
              <SmallMediumText color={grayWhite}>{unreadCount}</SmallMediumText>
            </TotalUnreadCount>
            {children}
          </>
        ) : (
          <IconBackground>
            <Icon name="ChatBubbleOutline" color={purpleBase} data-testid="chatIcon" />
            <TotalUnreadCount
              $count={unreadCount}
              $design="old"
              data-testid="chatNotificationsUnreadCount"
            >
              <SmallMediumText color={grayWhite}>{unreadCount}</SmallMediumText>
            </TotalUnreadCount>
          </IconBackground>
        )}
      </IconContainer>
    </Popover>
  );
};
