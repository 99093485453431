import { grayLight100 } from "@RooUI";
import moment from "moment";
import React, { useCallback } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  DatePickerProps as ReactDatePickerProps,
} from "react-datepicker";
import styled from "styled-components";

import { RooIcon, SegmentedControl, VStack } from "@RooBeta/components";

import "./DatePicker.css";
import { format } from "date-fns";

type DatePickerFormat = "range" | "dates";

export type DatePickerValue =
  | { format: "range"; startDate?: Date; endDate?: Date }
  | { format: "dates"; dates?: Date[] };

type DatePickerProps = Omit<
  ReactDatePickerProps,
  | "renderCustomHeader"
  | "selected"
  | "startDate"
  | "endDate"
  | "selectedDates"
  | "onChange"
  | "selectsRange"
  | "selectsMultiple"
  | "inline"
> & {
  value: DatePickerValue;
  onChange: (value: DatePickerValue) => void;
  getFilteredRange?: () => Date[];
};

export const DatePicker = ({
  value,
  onChange,
  minDate = new Date(),
  maxDate = moment().add(1, "year").toDate(),
  getFilteredRange,
  ...props
}: DatePickerProps) => {
  const onDateRangeChange = useCallback(
    ([start, end]: [Date | null, Date | null]) =>
      onChange({ format: "range", startDate: start ?? undefined, endDate: end ?? undefined }),
    [onChange]
  );

  const onDatesChanged = useCallback(
    (dates: Date[]) => onChange({ format: "dates", dates }),
    [onChange]
  );

  const onFormatChanged = useCallback(
    (format: DatePickerFormat) => {
      if (format !== value.format) {
        onChange({ format });
      }
    },
    [value.format, onChange]
  );

  const renderCustomHeader = useCallback(
    (props: ReactDatePickerCustomHeaderProps) => (
      <DatePickerHeader {...props} value={value} onChange={onFormatChanged} />
    ),
    [value, onFormatChanged]
  );

  const filteredRange = getFilteredRange?.();

  // Highlight valid days in the range
  const highlightClassName = (date: Date) => {
    if (
      !filteredRange ||
      value.format !== "range" ||
      filteredRange.some((d) => format(d, "yyyy-MM-dd") === format(date, "yyyy-MM-dd"))
    ) {
      return "highlighted-day";
    }
    return "";
  };

  return value.format === "range" ? (
    <div data-testid="datePicker">
      <ReactDatePicker
        renderCustomHeader={renderCustomHeader}
        selected={value.startDate}
        startDate={value.startDate}
        endDate={value.endDate}
        onChange={onDateRangeChange}
        selectsRange
        inline
        minDate={minDate}
        maxDate={maxDate}
        dayClassName={highlightClassName}
        {...props}
      />
    </div>
  ) : (
    <div data-testid="datePicker">
      <ReactDatePicker
        renderCustomHeader={renderCustomHeader}
        selectedDates={value.dates}
        onChange={onDatesChanged}
        selectsMultiple
        inline
        minDate={minDate}
        maxDate={maxDate}
        dayClassName={highlightClassName}
        {...props}
      />
    </div>
  );
};

type DatePickerHeaderProps = ReactDatePickerCustomHeaderProps & {
  value: DatePickerValue;
  onChange: (format: DatePickerFormat) => void;
};

const OPTIONS: { label: string; value: DatePickerFormat; "data-testid": string }[] = [
  { label: "Range", value: "range", "data-testid": "rangePicker" },
  { label: "Days", value: "dates", "data-testid": "daysPicker" },
];

const formatDateRange = (startDate: Date, endDate: Date) => {
  const startMonth = startDate.toLocaleDateString("en-US", { month: "short" });
  const startDay = startDate.getDate();
  const endMonth = endDate.toLocaleDateString("en-US", { month: "short" });
  const endDay = endDate.getDate();

  if (startDate.getFullYear() !== endDate.getFullYear()) {
    return `${startMonth} ${startDay}, ${startDate.getFullYear()} - ${endMonth} ${endDay}, ${endDate.getFullYear()}`;
  }

  if (startDate.getMonth() === endDate.getMonth()) {
    return `${startMonth} ${startDay} - ${endDay}`;
  }

  return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
};

const DatePickerHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  onChange,
  value,
}: DatePickerHeaderProps) => (
  <VStack $justifyContent="center" $alignItems="center" $gap="s">
    <DatePickerHeaderContainer>
      <div
        onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}
        style={{ cursor: prevMonthButtonDisabled ? "not-allowed" : "pointer" }}
        data-testid="datePickerPrevMonth"
      >
        <RooIcon icon="chevron_left" color={prevMonthButtonDisabled ? grayLight100 : undefined} />
      </div>
      <div data-testid="datePickerCurrentMonth">
        {date.toLocaleDateString("en-US", { month: "short", year: "numeric" })}
      </div>
      <div
        onClick={nextMonthButtonDisabled ? undefined : increaseMonth}
        style={{ cursor: nextMonthButtonDisabled ? "not-allowed" : "pointer" }}
        data-testid="datePickerNextMonth"
      >
        <RooIcon icon="chevron_right" color={nextMonthButtonDisabled ? grayLight100 : undefined} />
      </div>
    </DatePickerHeaderContainer>
    {value.format === "range" && value.startDate && value.endDate && (
      <div>{formatDateRange(value.startDate, value.endDate)}</div>
    )}
    <SegmentedControl
      options={OPTIONS}
      selected={value.format}
      onChange={onChange}
      data-testid="datePickerFormat"
    />
  </VStack>
);

const DatePickerHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8px;
`;
