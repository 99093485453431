import { useRequiredAuthorizedUser } from "./useAuthorizedUser";

import { VET_ROUTES, TECH_ROUTES } from "@RooBeta/constants";

export const useRoutes = () => {
  const {
    provider: { providerType },
  } = useRequiredAuthorizedUser();

  const routes = providerType === "VET" ? VET_ROUTES : TECH_ROUTES;

  return routes;
};
