import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

import { greenBase, redBackground } from "../../styles/constants";
import { Button, ButtonProps } from "../Button";
import { FlexLayout } from "../FlexLayout";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { NestedTabs } from "../NestedTabs";

import {
  ActionContainer,
  BreadCrumbContainer,
  CloseContainer,
  Overlay,
  Panel as PanelComponent,
  PanelContainer,
} from "./PanelStyles";

export const ContextAlert = styled(FlexLayout)`
  background-color: ${redBackground};
`;

export interface PanelProps {
  $isShowPanel?: boolean;
  tabNameArr?: any;
  defaultSelectedTabIndex: number;
  onClickTab?: (e: any) => void;
  onClosePanel?: () => void;
  id?: string;
  $panelStyle?: "info" | "action";
  actionButtonProps?: ButtonProps;
  actionComponent?: React.ReactNode;
  breadcrumbText?: string;
  onBreadcrumbClick?: () => void;
}

export const Panel = (props: PanelProps) => {
  const {
    onClosePanel,
    $panelStyle,
    actionButtonProps,
    tabNameArr,
    defaultSelectedTabIndex,
    onClickTab,
    actionComponent,
    breadcrumbText,
    onBreadcrumbClick,
  } = props;
  return (
    <>
      {createPortal(
        <PanelContainer {...props}>
          <Overlay onClick={() => (onClosePanel ? onClosePanel() : null)} />
          <PanelComponent {...props} data-testid="panelContainer">
            <CloseContainer $hasBreadcrumb={!!breadcrumbText}>
              {breadcrumbText && (
                <BreadCrumbContainer>
                  <Icon
                    name="ArrowBack"
                    color={greenBase}
                    size="s"
                    onClick={() => (onBreadcrumbClick ? onBreadcrumbClick() : null)}
                  />
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onBreadcrumbClick ? onBreadcrumbClick() : null;
                    }}
                  >
                    {breadcrumbText}
                  </Link>
                </BreadCrumbContainer>
              )}
              <Icon
                name="Close"
                onClick={() => (onClosePanel ? onClosePanel() : null)}
                data-testid="closePanel"
              />
            </CloseContainer>
            <NestedTabs
              tabNameArr={tabNameArr}
              defaultSelectedTabIndex={defaultSelectedTabIndex}
              onClickTab={onClickTab}
              scrollbarSpace
            />

            {$panelStyle === "action" && (actionComponent || actionButtonProps) ? (
              <ActionContainer id="action-container">
                {actionComponent ? actionComponent : null}
                {actionButtonProps && (
                  <Button
                    data-testid="panelActionButton"
                    {...actionButtonProps}
                    $buttonType="primary"
                    $fullWidth={true}
                  />
                )}
              </ActionContainer>
            ) : null}
          </PanelComponent>
        </PanelContainer>,
        document.body
      )}
    </>
  );
};
