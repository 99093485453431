import { ConfigProvider, Slider } from "antd";
import { SliderSingleProps } from "antd/es/slider";
import React from "react";
import styled from "styled-components";
import {
  blueDark600,
  blueLight300,
  BodyText,
  grayLighter,
  primaryFont,
  SmallBodyText,
  spacingS,
  spacingXS,
} from "../../../styles/constants";

interface StyledSliderProps {
  $isRecommendedVariant?: boolean;
}

const SliderContainer = styled.div`
  padding: 0 ${spacingS};
`;

export const PriceLabel = styled.div`
  ${BodyText};
  margin-top: ${spacingXS};
`;

export const PriceSubLabel = styled.div`
  ${SmallBodyText};
`;

export const PriceLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  > div {
    text-align: center;
    flex: 1;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

const BaseSlider = ({ $isRecommendedVariant, ...props }: StyledSliderProps & SliderSingleProps) => (
  <SliderContainer>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: primaryFont,
          colorPrimaryBorderHover: blueDark600,
          colorFillContentHover: blueLight300,
        },
        components: {
          Slider: {
            trackBg: blueDark600,
            trackHoverBg: blueDark600,
            railBg: grayLighter,
            railHoverBg: grayLighter,
            handleColor: blueDark600,
            handleActiveColor: blueDark600,
            dotBorderColor: blueLight300,
            dotActiveBorderColor: blueLight300,
            handleLineWidth: 2,
            handleLineWidthHover: 2,
            handleSize: 20,
            handleSizeHover: 20,
            railSize: 6,
            dotSize: 12,
            margin: 0,
          },
        },
      }}
    >
      <Slider {...props} />
    </ConfigProvider>
  </SliderContainer>
);

export const StyledPricingSlider = styled(BaseSlider)<StyledSliderProps>`
  margin: 0px;

  .ant-slider-track,
  .ant-slider-track:hover {
    background: transparent !important;
  }

  .ant-slider-mark-text {
    font-size: 0;
    display: none;
  }

  &.ant-slider-horizontal.ant-slider-with-marks {
    margin-bottom: 0px;
  }

  ${({ $isRecommendedVariant }) =>
    $isRecommendedVariant &&
    `
    .ant-slider-dot:nth-child(1),
    .ant-slider-dot:nth-child(3) {
      border-color: ${blueLight300};
    }

    .ant-slider-dot:nth-child(2) {
      background: ${blueDark600};
      border-color: ${blueDark600};
    }
  `}
`;
