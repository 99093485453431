import { CollapsePanelProps, CollapseProps } from "antd";
import React, { PropsWithChildren } from "react";

import { StyledAccordion, StyledAccordionItem } from "./styles";

const Item = ({ children, ...rest }: CollapsePanelProps) => (
  <StyledAccordionItem {...rest}>{children}</StyledAccordionItem>
);

export type AccordionProps = PropsWithChildren<{
  defaultActiveIds?: string[];
  onToggle?: (keys: string[] | string) => void;
}> &
  Omit<CollapseProps, "defaultActiveKey" | "onChange">;

export const Accordion = ({ children, defaultActiveIds, onToggle, ...rest }: AccordionProps) => (
  <StyledAccordion
    defaultActiveKey={defaultActiveIds}
    onChange={(keys) => onToggle?.(keys)}
    {...rest}
  >
    {children}
  </StyledAccordion>
);

Accordion.Item = Item;
