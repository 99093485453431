import { Button, FlexLayout, SmallText, Text } from "@RooUI";
import React from "react";

import UserPromo from "@Roo/Promo/types/UserPromo";

interface ClaimPromoModalProps {
  promo: UserPromo;
  showLoading: boolean;
  onClaim: () => void;
  onClose: () => void;
}

export const ClaimPromoModal = ({ promo, showLoading, onClaim }: ClaimPromoModalProps) => (
  <FlexLayout align="center" vertical gap="m" data-testid={`modalPromo${promo.promoId}`}>
    <Text>{promo.promoBannerAndEmailText}</Text>
    <Button
      loading={showLoading}
      $buttonType="primary"
      onClick={onClaim}
      data-testid="startPromo"
      title="Claim Now"
    />
    <SmallText>{promo.promoDescription}</SmallText>
  </FlexLayout>
);
